import React from "react";

const Pdf = () => (
  <div style={{ textAlign: "left", fontWeight: 800 }}>
    <h2>
      Žádost o povolení zvláštního užívání pozemní komunikace pro pořádání sportovní nebo kulturně společenské akce
    </h2>
  </div>
);

export default Pdf;
