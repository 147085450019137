import React from "react";

// GraphQL query
import { Query } from "react-apollo";
import ORGANISATION_QUERY from "../../queries/organisation";

// Semantic UI
import Signed from "../Signed";
import DropzoneUpload from "../Dropzone";
import FooterForm from "./footerForm";
import Podavajici from "./Podavajici";
import Pouceni from "./Pouceni";

// Interfaces
import { AppProps, AppState, Data, Variables, LocalStorage } from "./types";

// Import Local storage
import StoreLocal from "../../libs/LocalStorage";

// Import regular stylesheet

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheck,
  faClipboardCheck,
  faExclamation,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";

library.add(faSpinner, faCheck, faExclamation);

class Footer extends React.Component<AppProps, AppState> {
  private error = true;
  private storeLocal: LocalStorage = new StoreLocal();
  /** ID for local storage */
  private id: string = this.props.formsId.concat(this.props.footerId);
  private componentName = "form_footer";
  private date: string = new Date().toISOString().substring(0, 10);

  constructor(props: AppProps) {
    super(props);
    this.state = {
      signedModal: false,
      passwordVerified: false,
      data: {},
      mainValidatorError: true
    };
    this.signedToggle = this.signedToggle.bind(this);
  }

  private updatePodavajiciData = async (data: any) => {
    this.state.data["Podavajici"] = await data;
    await this.props.updateData(this.props.footerId, this.state.data);
    if (data) {
      const mainError = await data.mainError;
      await this.setState({ mainValidatorError: mainError });
    }
  };

  private updateFormData = async (data: Object) => {
    this.state.data["Misto"] = data;
    await this.props.updateData(this.props.footerId, this.state.data);
  };

  private handleReload = () => {
    window.location.reload();
  };

  private signedToggle = () => {
    this.setState(prevState => ({
      signedModal: !prevState.signedModal
    }));
  };

  private handleSendKeysToSubmit = async (privkey: any, pubkey: any) => {
    this.props.sendKeysToSubmit(privkey, pubkey);
  };

  private handleAddAcceptedAtachments = async (acceptedFiles: any) => {
    await this.props.sendAttachmentsToSubmit(acceptedFiles);
  };

  private handlePasswordVerified = async (verified: boolean) => {
    await this.setState({ passwordVerified: verified });

    await setTimeout(
      function() {
        this.setState({ signedModal: false });
      }.bind(this),
      3467
    );
  };

  public render() {
    const id_organisations = this.props.id_organisations;
    return (
      <Query<Data, Variables> query={ORGANISATION_QUERY} variables={{ id_organisations }}>
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div>
                <FontAwesomeIcon icon={faSpinner} />
              </div>
            );
          if (error) return <div>Error loading tha data: {error}</div>;
          const _data: any = data;
          const organisation: any = _data.organisation;
          const registryVerified: any = organisation.registry_verified;
          return (
            <div className={"col pb-3"}>
              <Podavajici
                prototypeId={this.props.formsId}
                footerId={this.props.footerId}
                data={this.state.data}
                updateData={this.updatePodavajiciData}
              />
              <Pouceni />
              <div>
                <DropzoneUpload handleAddAcceptedAtachments={this.handleAddAcceptedAtachments} />
              </div>
              <fieldset className={"mt-5"}>
                <legend>
                  <FontAwesomeIcon icon={faCheck} className={"mr-2"} /> Potvrzení a odeslání
                </legend>
                <div className={this.props.submitted ? "d-flex justify-content-center" : "d-none"}>
                  <Spinner style={{ width: "4rem", height: "4rem" }} color={"primary"} type="grow" />
                </div>
                <div className={this.props.formResolved || this.props.submitted ? "d-none" : ""}>
                  <FooterForm updateData={this.updateFormData} id={this.id} />
                  <div>
                    <Modal
                      isOpen={this.state.signedModal}
                      toggle={this.signedToggle}
                      onClose={this.signedToggle}
                      backdrop={true}
                    >
                      <ModalHeader>Podepište formulář</ModalHeader>
                      <ModalBody>
                        <Signed
                          passwordVerified={this.handlePasswordVerified}
                          handleReload={this.handleReload}
                          submittingSignedForm={this.props.submittingSignedForm}
                          certIssuer={this.props.certIssuer}
                          sendKeysToSubmit={this.handleSendKeysToSubmit}
                        />
                      </ModalBody>
                    </Modal>
                  </div>
                  <small className={"row justify-content-center"}>
                    Prohlašuji, že jsem veškeré údaje uvedl úplně a správně.
                  </small>
                  <small>
                    Odesláním vyplněného formuláře na webových stránkách www.vhodne-uverejneni.cz uděluji společnosti
                    QCM, s.r.o. souhlas, aby předala moje osobní údaje, zadané do tohoto formuláře, na adresu podatelny
                    příjemce podání, uvedené v hlavičce tohoto formuláře. Společnost QCM, s.r.o. nebude data kromě
                    předání na adresu podatelny příjemce podání data jakýmkoli způsobem zpracovávat nebo uchovávat.
                    Podrobnější informace o zpracování osobních údajů jsou k dispozici{" "}
                    <a href="https://www.vhodne-uverejneni.cz/gdpr" target="_blank">
                      zde.
                    </a>
                  </small>
                  <div className={!this.state.passwordVerified ? "d-none" : "row justify-content-center mt-2"}>
                    <Alert className={"col-6 text-center"} color={"success"}>
                      <FontAwesomeIcon icon={faCheck} /> Podpis byl ověřen
                    </Alert>
                  </div>
                  <div className={"row justify-content-center mt-3"}>
                    <div className={this.state.passwordVerified ? "col-md-6 col-xl-4" : "d-none"}>
                      <button
                        disabled={!registryVerified}
                        className={this.state.passwordVerified ? "btn btn-primary w-100 text-uppercase" : "d-none"}
                        type="submit"
                      >
                        Odeslat podepsaný formulář
                      </button>
                    </div>
                    <div className={!this.state.passwordVerified ? "col-md-6 col-xl-4" : "d-none"}>
                      <Button
                        disabled={this.props.formResolved || this.props.submitted || !registryVerified}
                        className={!this.state.passwordVerified ? "btn btn-primary w-100 text-uppercase" : "d-none"}
                        type="button"
                        onClick={this.signedToggle}
                      >
                        Odeslat s podpisem
                      </Button>
                    </div>
                    <div className={"col-md-6 col-xl-4 mt-3 mt-md-0 mt-lg-3 mt-xl-0"}>
                      <Button
                        disabled={
                          this.props.formResolved ||
                          this.props.submitted ||
                          this.state.passwordVerified ||
                          !registryVerified
                        }
                        className={"btn btn-outline-primary w-100 text-uppercase"}
                        type="submit"
                      >
                        Odeslat bez podpisu
                      </Button>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default Footer;
