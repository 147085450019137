import React, { Component } from "react";
import PopisProps from "./PopisProps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "reactstrap";
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import InputTextarea from "../../InputTextarea";

export default class DrevinyPopis extends Component<PopisProps> {
  constructor(props: PopisProps) {
    super(props);
  }

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faClipboard} className={"mr-2"} /> Popis kácení
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="katastralni_uzemi" className="col-lg-3 col-form-label text-lg-right">
            Katastrální území
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="katastralni_uzemi"
                name={"katastralni_uzemi"}
                value={this.props.data.katastralni_uzemi}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="parcela" className="col-lg-3 col-form-label text-lg-right">
            Parcela
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="parcela"
                name={"parcela"}
                value={this.props.data.parcela}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="strucny_popis" className="col-lg-3 col-form-label text-lg-right">
            Stručný popis umístění
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <InputTextarea
                className="form-control"
                id="strucny_popis"
                name={"strucny_popis"}
                value={this.props.data.strucny_popis}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="druh_dreviny" className="col-lg-3 col-form-label text-lg-right">
            Druh dřeviny
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type={"text"}
                className="form-control"
                id="druh_dreviny"
                name={"druh_dreviny"}
                value={this.props.data.druh_dreviny}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="pocet_kacenych_drevin" className="col-lg-3 col-form-label text-lg-right">
            Počet kácených dřevin
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type={"number"}
                className="form-control"
                id="pocet_kacenych_drevin"
                name={"pocet_kacenych_drevin"}
                value={this.props.data.pocet_kacenych_drevin}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="vymera_kacene_plochy" className="col-lg-3 col-form-label text-lg-right">
            Výměra kácené plochy
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type={"number"}
                className="form-control"
                id="vymera_kacene_plochy"
                name={"vymera_kacene_plochy"}
                value={this.props.data.vymera_kacene_plochy}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="odvod_kmene" className="col-lg-3 col-form-label text-lg-right">
            Obvod kmene ve výšce 130cm nad zemí
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type={"number"}
                className="form-control"
                id="odvod_kmene"
                name={"odvod_kmene"}
                value={this.props.data.odvod_kmene}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
