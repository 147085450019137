import React from "react";
import "react-day-picker/lib/style.css";
// @ts-ignore
import MomentLocaleUtils, { formatDate, parseDate } from "react-day-picker/moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment = require("moment");

moment.locale("cs");

const WEEKDAYS_SHORT = ["Po", "Út", "St", "Čt", "Pá", "So", "Ne"];
const WEEKDAYS_LONG = ["Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota", "Neděle"];
const MONTHS = [
  "Leden",
  "Únor",
  "Březen",
  "Duben",
  "Květen",
  "Červen",
  "Červenec",
  "Srpen",
  "Září",
  "Říjen",
  "Listopad",
  "Prosinec"
];
const FIRST_DAY = 0;
const LABELS = { nextMonth: "Další měsíc", previousMonth: "Předešlý měsíc" };

export default class DatePickerInput extends React.Component<any> {
  constructor(props) {
    super(props);
  }

  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    return (
      <DayPickerInput
        format="LL"
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={`${moment().format("Do MMMM YYYY")}`}
        dayPickerProps={{
          locale: "cs",
          localeUtils: MomentLocaleUtils
        }}
        classNames={{
          container: "DayPickerInput col-12",
          overlayWrapper: "DayPickerInput-OverlayWrapper",
          overlay: "DayPickerInput-Overlay"
        }}
        onDayChange={this.props.onChange}
        value={this.props.value}
      />
    );
  }
}
