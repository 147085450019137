import * as React from "react";
import "./forms.scss";
import Button from "@material-ui/core/Button";

export interface Props {}

export interface State {}

class Prostranstvi extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { trimmedDataURL: null };
  }
  sigPad: any = {};

  clear = () => {
    this.sigPad.clear();
  };
  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png")
    });
  };
  render() {
    let trimmedDataURL = this.state;
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    let now: any = new Date().toISOString().substring(0, 10);
    return (
      <div className="the-form">
        <div className="header-a">PŘIZNÁNÍ</div>
        <div className="sub-header">místního poplatku za užívání veřejného prostranství</div>
        <div className="sub-sub-header-a">
          Správce poplatku: Městský úřad Velká obec, Dukelské nám. 2/2, 693 17 Velká obec
        </div>
        <div className="sub-sub-header-a">
          Dle ustanovení § 14a zákona č. 595/1990 Sb., o místních poplatcích, ve znění pozdějších předpisů, jste povinen
          ohlásit tyto údaje:
        </div>
        <div className="block-a">
          <div className="row">
            <div className="description-a">Poplatník</div>
            <span className="input-label-a">Jméno</span>
            <span>
              <input className="input-a" />
            </span>
          </div>
          <div className="row">
            <div className="description-a" />
            <span className="input-label-a">Příjmení</span>
            <span>
              <input className="input-a" />
            </span>
          </div>
          <div className="row">
            <div className="description-a" />
            <span className="input-label-a">R.Č. (IČ) </span>
            <span>
              <input className="input-a" />
            </span>
          </div>
          <div className="row">
            <div className="description-a" />
            <span className="input-label-a">Adresa</span>
            <span>
              <input className="input-a" />
            </span>
          </div>
        </div>
        <div className="description">
          Podávám přiznání k platbě místního poplatku za užívání veřejného prostranství na místo a účel užívání:
        </div>
        <div className="block-a">
          <div className="row">
            <span className="input-label-b">Dohodnuto dne</span>
            <span>
              <input className="input-b" />
            </span>
          </div>
          <div className="row">
            <span className="input-label-b">Počet m2</span>
            <span>
              <input className="input-b" />
            </span>
          </div>
          <div className="row">
            <span className="input-label-b">Poplatková povinnost od – do</span>
            <span>
              <input className="input-b" />
            </span>
            <span className="input-label-c">Počet dnů</span>
            <span>
              <input className="input-c" />
            </span>
          </div>
          <div className="row">
            <span className="input-label-b">Sazba poplatku za 1 den (dle OZV, čl. 15)</span>
            <span>
              <input className="input-b" />
            </span>
          </div>
          <div className="row">
            <span className="input-label-b">Poplatek celkem </span>
            <span>
              <input className="input-b" />
            </span>
          </div>
        </div>

        <div className="block-b">
          <div className="row">
            <div className="description-b">Podávající</div>
            <span className="input-label-d">Jméno a příjmení</span>
            <span>
              <input className="input-a" />
            </span>
          </div>
          <div className="row">
            <div className="description-a" />
            <span className="input-label-d">Kontaktní telefon</span>
            <span>
              <input className="input-a" />
            </span>
          </div>
          <div className="row">
            <div className="description-a" />
            <div className="input-label-d">Kontaktní email</div>
            <span>
              <input className="input-a" />
            </span>
          </div>
          <div className="row">
            <div className="description-a" />
            <div className="input-sub-label-b">(sem bude odeslána kopie formuláře)</div>
            <span />
          </div>
        </div>

        <div className="description">
          Poučení: Dojde-li ke změně údajů uvedených v ohlášení, je poplatník povinen tuto změnu oznámit do 15 dnů ode
          dne, kdy nastala a to dle ustanovení § 14a odst. 3) zákona č. 565/1990 Sb., o místních poplatcích, ve znění
          pozdějších předpisů.
        </div>
        <div className="description">Prohlašuji, že jsem veškeré údaje uvedl úplně a správně.</div>
        <div className="date">
          <span className="date-text">V</span>
          <span className="date-input">
            <input className="input-date" />
          </span>
          <span className="date-text">Dne</span>
          <span className="date-input">
            <input className="input-date" type="date" data-date="" data-date-format="DD MMMM YYYY" value={now} />
          </span>
        </div>
        <div className="signature-description">Podpis</div>
        <div className="signature-btn-container">
          <Button variant="outlined" className={"signature-btn"} onClick={this.clear}>
            Smaž podpis
          </Button>
        </div>
        <div className="odeslat">
          <Button variant="outlined" className="odeslat-btn">
            Odeslat
          </Button>
        </div>
      </div>
    );
  }
}

export default Prostranstvi;
