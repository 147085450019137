import React from "react";

// Import regular stylesheet

// Import Local storage
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";

// Interfaces
export interface AppProps {
  data: any;
  name?: string;
}

class InformaceSubjekt extends React.Component<AppProps> {
  private componentName = "form_informace_subjekt";

  constructor(props: AppProps) {
    super(props);
  }

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faUser} className={"mr-2"} /> Povinný subjekt
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_subjekt_name" className="col-lg-3 col-form-label text-lg-right">
            Název
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_subjekt_name"
                name={"informace_subjekt_name"}
                value={this.props.data.informace_subjekt_name}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_subjekt_urad" className="col-lg-3 col-form-label text-lg-right">
            Úřad
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_subjekt_urad"
                name={"informace_subjekt_urad"}
                value={this.props.data.informace_subjekt_urad}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_subjekt_adresa" className="col-lg-3 col-form-label text-lg-right">
            Adresa
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_subjekt_adresa"
                name={"informace_subjekt_adresa"}
                value={this.props.data.informace_subjekt_adresa}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_subjekt_mesto" className="col-lg-3 col-form-label text-lg-right">
            Město
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_subjekt_mesto"
                name={"informace_subjekt_mesto"}
                value={this.props.data.informace_subjekt_mesto}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default InformaceSubjekt;
