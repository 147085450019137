import React from "react";
import { Link, Redirect } from "react-router-dom";
import "../../styles/app.css";

// GraphQL query
import { Query } from "react-apollo";
import ORGANISATION_LINK_QUERY from "../../queries/organisationLink";

// Import regular stylesheet

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faExclamationCircle,
  faInfoCircle,
  faList
} from "@fortawesome/free-solid-svg-icons";
import { Grid } from "semantic-ui-react";
import { render } from "react-dom";
import { Alert, Button } from "reactstrap";

library.add(faClipboardList);

// Interfaces
export interface AppProps {
  data: any;
  name?: string;
  match: any;
  history: any;
  location: any;
}

export interface Data {
  organisations: {
    id_organisations: string;
    name: string;
    slug: string;
    street_name: string;
    house_number: string;
    city: string;
    zip_code: string;
    phone_number: string;
    contact_email: string;
    registry_email: string;
    registry_verified: boolean;
    created: string;
    modified: string;
    bought_forms: {
      id_bought_forms: string;
      id_forms: string;
      id_organisations: string;
      is_public: string;
      created: string;
      modified: string;
    };
  };
}

export interface Variables {
  organisation_slug: string;
}

class Office extends React.Component<AppProps, any> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      publicFormCounter: 0
    };
  }

  renderFormsLinks = (organisation: any) => {
    if (organisation.bought_forms.some(k => k.is_public === true)) {
      return (
        <div className={"w-100"}>
          <div className={"row justify-content-center text-center mt-4"}>
            <div className={"col-12"}>
              <h2>
                {organisation.name}, {organisation.street_name} {organisation.house_number}, {organisation.city}{" "}
                {organisation.zip_code}
              </h2>
            </div>
            <div className={"row justify-content-center col-12 mt-1"}>
              <div className={"col-2 border-bottom border-eforms border-bottom-width-3"}></div>
            </div>
            <div className={"col-12 mt-2"}>
              <h6 className={"font-weight-light"}>Oficiální elektronické formuláře pro podání občanů</h6>
            </div>
            <div className={"col-12"}>
              <h6 className={organisation.registry_verified ? "font-weight-light" : "font-weight-normal text-danger"}>
                {organisation.registry_verified
                  ? `Formuláře jsou odesílány na podatelnu úřadu ${
                      organisation.registry_email ? organisation.registry_email : ""
                    }`
                  : `Obec nemá ověřený e-mail podatelny, formuláře není možné elektronicky podat.`}
              </h6>
            </div>
          </div>
          <div className={"row mt-3 mb-3"}>
            {organisation.bought_forms.map((bought_form: any, key: any) => {
              if (bought_form.is_public) {
                return (
                  <div className="col-xl-4 col-md-6">
                    <div className="card d-flex border-0 h-100 py-2">
                      <Link
                        className={"text-body form-link text-decoration-none"}
                        to={`/${organisation.slug}/${bought_form.form.slug}`}
                      >
                        <div className="card-body align-items-center d-block">
                          <div className="row no-gutters align-items-center">
                            <div className="btn btn-eforms rounded-circle">
                              <FontAwesomeIcon icon={faList} />
                            </div>
                            <div className="col-7 col-xl-8 col-md-6 col-sm-8 ml-2">
                              <div key={key} className="text-xs mb-1">
                                {bought_form.form.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className={"row justify-content-center text-center border-top pt-5"}>
            <div className={"col-12"}>
              <h2>NÁVOD K POUŽITÍ</h2>
            </div>
            <div className={"row justify-content-center col-12 mt-1"}>
              <div className={"col-2 border-bottom border-eforms border-bottom-width-3"}></div>
            </div>
          </div>
          <div className="row d-block align-items-stretch">
            <div className="card-group">
              <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h2 className="faq-heading">01.</h2>
                    <h5 className="card-title text-uppercase">Vyplňte podání na webu</h5>
                    <p className="card-text font-weight-light text-muted">
                      Nepotřebujete žádný elektronický podpis. Pokud něco nedokážete vyplnit, později Vám pomůžeme.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h2 className="faq-heading">02.</h2>
                    <h5 className="card-title text-uppercase">Odešlete formulář</h5>
                    <p className="card-text font-weight-light text-muted">
                      Kopii podání dostanete do Vaší e-mailové schránky. Úřad dostane podání úředním kanálem – do
                      podatelny.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h2 className="faq-heading">03.</h2>
                    <h5 className="card-title text-uppercase">Dostavíte se na úřad</h5>
                    <p className="card-text font-weight-light text-muted">
                      Máte celých 5 dní k potvrzení a dokončení podání, pokud jste ho nepodali se všemi náležitostmi.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h2 className="faq-heading">04.</h2>
                    <h5 className="card-title text-uppercase">Úřad Vám pomůže</h5>
                    <p className="card-text font-weight-light text-muted">
                      Na úřadě Vaše elektronické podání­ pomůže dokončit úředník. Můžete zaplatit pří­padné poplatky.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Alert color={"info"} className={"row justify-content-center align-items-center col-12 mt-5 text-center"}>
        <FontAwesomeIcon size={"4x"} icon={faInfoCircle} className={"mr-2"} /> Organizace {organisation.name} nemá
        momentálně k dispozici žádný elektronický formulář.
      </Alert>
    );
  };

  render() {
    const organisation_slug = this.props.match.params.organisation;
    return (
      <Query<Data, Variables>
        query={ORGANISATION_LINK_QUERY}
        fetchPolicy={"network-only"}
        variables={{ organisation_slug }}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Nahrávání dat</div>; // TODO loader
          if (error) return <div>Chyba při nahrávání dat: {error}</div>;
          const _data: any = data;
          const organisation: any = _data.orgforms;
          if (organisation) {
            return <div className={"row"}>{this.renderFormsLinks(organisation)}</div>;
          }

          return (
            <Alert color={"info"} className={"row justify-content-center align-items-center col-12 mt-5 text-center"}>
              <FontAwesomeIcon size={"4x"} icon={faInfoCircle} className={"mr-2"} /> Daná organizace neexistuje.
            </Alert>
          );
        }}
      </Query>
    );
  }
}

export default Office;
