import React from "react";

export interface AppProps {
  officeId: string;
  prototypeId: string;
  componentId: string;
  data: Object;
  updateData(offficeId: string, prototypeId: string, componentId: string, componentName: string, data: Object): void;
}

class FormKapacitaHeader extends React.Component<AppProps> {
  private componentName = "form_kapacita_header";
  constructor(props: AppProps) {
    super(props);
  }

  public componentDidMount = async () => {
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.props.data
    );
  };

  public render() {
    return (
      <div className="row mb-4">
        <div className="col">
          <div className={"text-center"}>
            <h2>
              <strong>Přiznání místního poplatku z ubytovací kapacity</strong>
            </h2>
            Dle ustanovení § 14a zákona č. 595/1990 Sb., o místních poplatcích, ve znění pozdějších předpisů, jste
            povinen ohlásit tyto údaje:
          </div>
        </div>
      </div>
    );
  }
}

export default FormKapacitaHeader;
