import React from "react";

const DogDetails = ({ barva, cislo_cipu, cislo_znamky, drzen_od, jmeno, plemeno, pohlavi, stari, vyska }) => (
  <React.Fragment>
    <div className={"section-title mt-2"} style={{ textDecoration: "underline" }}>
      {jmeno}
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Číslo známky:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{cislo_znamky}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Číslo čipu:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{cislo_cipu}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Plemeno:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{plemeno}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Stáří:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{stari}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Výška v kohoutku:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{vyska}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Barva srsti:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{barva}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Pohlaví:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{pohlavi}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Držen od:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{drzen_od}</span>
      </div>
    </div>
  </React.Fragment>
);

export default DogDetails;
