/**
 * Local Storage Abstractions
 */

class StoreLocal {
  private storageAvailable: boolean = false;
  constructor() {
    if (this.isStorageAvailable("localStorage")) {
      this.storageAvailable = true;
      // console.log('Storage Available');
    } else {
      this.storageAvailable = false;
      console.log("Storage Unvailable");
    }
  }

  isStorageAvailable = (type: any) => {
    let storage: any;

    try {
      storage = window[type];
      let x = "__storage_test__";
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === "QuotaExceededError" ||
          // Firefox
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        // acknowledge QuotaExceededError only if there's something already stored
        (storage && storage.length !== 0)
      );
    }
  };

  clearLocalStorage = () => {
    if (this.storageAvailable) {
      try {
        localStorage.clear();
      } catch (err) {
        console.log(err);
      }
    }
  };

  saveToLocalStorage = (index: string, object: string) => {
    if (this.storageAvailable) {
      try {
        localStorage.setItem(index, object);
      } catch (err) {
        console.log(err);
      }
    }
  };

  getFromLocalStorage = (index: string) => {
    if (this.storageAvailable) {
      try {
        return localStorage.getItem(index);
      } catch (err) {
        console.log(err);
      }
    }
  };
}

export default StoreLocal;
