import * as React from "react";
import { VyjadreniProps } from "./VyjadreniProps";
import { printBool } from "../../../helpers";

const ProstrediVyjadreniPdf = ({
  data: { akce, katastralni_uzemi, vratit_dokumentaci, vyjadreni_osobne, vyjadreni_zaslat, zaslat_na_urad }
}: VyjadreniProps) => (
  <React.Fragment>
    <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
      Vyjádření k projektové dokumentaci
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Akce:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{akce}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Katastrální území:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{katastralni_uzemi}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Vyjádření zaslat na adresu žadatele:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{printBool(vyjadreni_zaslat)}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Vyjádření si převezmu osobně:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{printBool(vyjadreni_osobne)}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Požaduji vrátit projektovou dokumentaci:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{printBool(vratit_dokumentaci)}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Vyjádření zaslat s projektovou dokumentací na stavební úřad:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{printBool(zaslat_na_urad)}</span>
      </div>
    </div>
  </React.Fragment>
);

export default ProstrediVyjadreniPdf;
