import React from "react";
import ZadostProps from "./ZadostProps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons/faClipboardCheck";
import typZadosti from "./typZadosti";

export default class FormProstrediZadost extends React.Component<ZadostProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faClipboardCheck} className={"mr-2"} /> Typ žádosti
        </legend>

        <div className="form-group row justify-content-md-center">
          <div className="row col-lg-6">
            <div className="col-12 custom-control custom-radio">
              <input
                type="radio"
                id="ucel1"
                name="ucel"
                className="custom-control-input"
                value={1}
                checked={this.props.data.ucel === 1}
              />
              <label className="custom-control-label" htmlFor="ucel1">
                {typZadosti[1]}
              </label>
            </div>

            <div className="col-12 custom-control custom-radio">
              <input
                type="radio"
                id="ucel2"
                name="ucel"
                value={2}
                checked={this.props.data.ucel === 2}
                className="custom-control-input"
                readOnly={true}
              />
              <label className="custom-control-label" htmlFor="ucel2">
                {typZadosti[2]}
              </label>
            </div>

            <div className="col-12 custom-control custom-radio">
              <input
                type="radio"
                id="ucel3"
                name="ucel"
                value={3}
                checked={this.props.data.ucel === 3}
                className="custom-control-input"
                readOnly={true}
              />
              <label className="custom-control-label" htmlFor="ucel3">
                {typZadosti[3]}
              </label>
            </div>

            <div className="col-12 custom-control custom-radio">
              <input
                type="radio"
                id="ucel4"
                name="ucel"
                value={4}
                checked={this.props.data.ucel === 4}
                className="custom-control-input"
                readOnly={true}
              />
              <label className="custom-control-label" htmlFor="ucel4">
                {typZadosti[4]}
              </label>
            </div>

            <div className="col-12 custom-control custom-radio">
              <input
                type="radio"
                id="ucel5"
                name="ucel"
                value={5}
                checked={this.props.data.ucel === 5}
                className="custom-control-input"
                readOnly={true}
              />
              <label className="custom-control-label" htmlFor="ucel5">
                {typZadosti[5]}
              </label>
            </div>

            <div className="col-12 custom-control custom-radio">
              <input
                type="radio"
                id="ucel6"
                name="ucel"
                value={6}
                checked={this.props.data.ucel === 6}
                className="custom-control-input"
                readOnly={true}
              />
              <label className="custom-control-label" htmlFor="ucel6">
                {typZadosti[6]}
              </label>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
