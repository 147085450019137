import React from "react";

const PersonDetails = ({ adresa, datum_narozeni, jmeno, prijmeni }) => (
  <React.Fragment>
    <div className={"row"}>
      <div className={"label-title col-3"}>Jméno:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{jmeno}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Příjmení:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{prijmeni}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>RČ / datum narození:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{datum_narozeni}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Adresa:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{adresa}</span>
      </div>
    </div>
  </React.Fragment>
);

export default PersonDetails;
