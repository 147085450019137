import React from "react";

// Interfaces
export interface AppProps {
  data: any;
  name?: string;
}

class ResultHeader extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
  }

  public render() {
    if (this.props.data) {
      const organisation = this.props.data.organisation || {};
      const street_name = organisation.street_name || "";
      const home_number = organisation.home_number || "";
      const zip_code = organisation.zip_code || "";
      let address = "";
      if (street_name && home_number) {
        address = street_name.concat(", ", home_number);
      }
      let municipality = "";
      if (organisation && zip_code) {
        municipality = organisation.city.concat(", ", zip_code);
      }

      return (
        <div>
          <div className="row mt-3">
            <div className="col-md-6 col-lg-12">
              <div>
                <fieldset>
                  <strong>
                    {organisation.name && <span className="font-weight-bold">{organisation.name},&nbsp;</span>}
                    {address && <span className="font-weight-bold">{address},&nbsp;</span>}
                    {municipality && <span className="font-weight-bold">{municipality}&nbsp;</span>}
                    <br />
                    {organisation.phone_number && (
                      <span className="font-weight-normal">tel.: {organisation.phone_number},&nbsp;</span>
                    )}
                    {organisation.contact_email && (
                      <span className="font-weight-normal">e-mail: {organisation.contact_email},&nbsp;</span>
                    )}
                    {organisation.www && (
                      <span className="font-weight-normal">
                        www: <a href={`${organisation.www}`}>{organisation.www}</a>
                      </span>
                    )}
                  </strong>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ResultHeader;
