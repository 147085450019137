import React from "react";

class FormProstranstviHeader extends React.Component {
  public render() {
    return (
      <div className="row mb-4 mt-5">
        <div className="col">
          <div className={"text-center"}>
            <h2>
              <strong>Přiznání místního poplatku za užívání veřejného prostranství</strong>
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

export default FormProstranstviHeader;
