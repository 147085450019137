import React from "react";
import PersonDetail from "./PersonDetail";
import { printBool } from "../../../helpers";

const Pdf = ({ data: { poplatnik, poplatnik_zastupce, povinnost } }) => (
  <React.Fragment>
    <div className={"row mt-5"}>
      <div className={"label-title col-3"}>Poplatková povinnost vznikla od:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{povinnost}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Poplatník je zároveň společný zástupce:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{printBool(poplatnik_zastupce)}</span>
      </div>
    </div>
    {poplatnik.map((osoba, index) => (
      <React.Fragment key={index}>
        <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
          {index + 1}. poplatník
        </div>
        <PersonDetail {...osoba} />
      </React.Fragment>
    ))}
  </React.Fragment>
);

export default Pdf;
