import React from "react";
import Button from "@material-ui/core/Button";

import "./forms.scss";

export interface Props {}

export interface State {}

class Psi extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { trimmedDataURL: null };
  }
  sigPad: any = {};

  clear = () => {
    this.sigPad.clear();
  };
  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png")
    });
  };
  render() {
    let now: any = new Date().toISOString().substring(0, 10);
    return (
      <div className="the-form">
        <div className="mail-address">
          <div>Městský úřad Velká Obec</div>
          <div>Náměstí míru 112/2</div>
          <div>500 00 Velká Obec</div>
          <div>podatelna@velkaobec.cz</div>
        </div>

        <div className="header">Hlášení k místnímu poplatku ze psů</div>
        <div className="sub-header">Fyzická osoba</div>
        <div className="sub-sub-header">
          (dle Obecně závazné vyhlášky města Velká Obec č. 1/2016 o místních poplatcích)
        </div>
        <div className="block-a">
          <div className="row reddish">
            <span className="input-label">Jméno a příjmení držitele psa</span>
            <span>
              <input className="input" />
            </span>
          </div>

          <div className="row reddish">
            <span className="input-label">Rodné číslo držitele</span>
            <span>
              <input className="input" />
            </span>
          </div>

          <div className="row reddish">
            <span className="input-label">Adresa místa trvalého pobytu držitele</span>
            <span>
              <input className="input" />
            </span>
          </div>

          <div className="row reddish">
            <span className="input-label">
              Doručovací adresa držitele (pokud se liší od adresy místa trvalého pobytu)
            </span>
            <span>
              <input className="input" />
            </span>
          </div>

          <div className="row reddish">
            <span className="input-label">Počet bytů v budově, ve které je držitel psa hlášen k trvalému pobytu</span>
            <span>
              <input className="input" />
            </span>
          </div>

          <div className="row reddish">
            <span className="input-label">
              Jsem – nejsem poživatelem starobního, invalidního, vdovského, vdoveckého nebo sirotčího důchodu. Tento
              důchod je – není jediným zdrojem mého příjmu. (Nehodící se škrtněte)
            </span>
            <span>
              <input className="input" />
            </span>
          </div>

          <div className="row reddish">
            <span className="input-label">Stáří psa (v měsících nebo létech)</span>
            <span>
              <input className="input" />
            </span>
          </div>

          <div className="row reddish">
            <span className="input-label">Datum, od kterého je pes držen</span>
            <span>
              <input className="input" />
            </span>
          </div>
        </div>
        <div className="description">Popis psa</div>

        <div className="block-a">
          <div className="row grey">
            <span className="input-label">Číslo čipu</span>
            <span>
              <input className="input" />
            </span>
          </div>

          <div className="row grey">
            <span className="input-label">Plemeno</span>
            <span>
              <input className="input" />
            </span>
          </div>

          <div className="row grey">
            <span className="input-label">Výška psa v kohoutku</span>
            <span>
              <input className="input" />
            </span>
          </div>

          <div className="row grey">
            <span className="input-label">Barva srsti psa</span>
            <span>
              <input className="input" />
            </span>
          </div>

          <div className="row grey">
            <span className="input-label">Pohlaví psa</span>
            <span>
              <input className="input" />
            </span>
          </div>
        </div>
        <div className="description">Podávající</div>
        <div className="block-a">
          <div className="row">
            <span className="input-label">Jméno a příjmení</span>
            <span>
              <input className="input" />
            </span>
          </div>
          <div className="row">
            <span className="input-label">Kontaktní telefon</span>
            <span>
              <input className="input" />
            </span>
          </div>
          <div className="row">
            <div className="input-label">Kontaktní email</div>
            <span>
              <input className="input" />
            </span>
          </div>
          <div className="rowas">
            <div className="input-sub-label">(sem bude odeslána kopie formuláře)</div>
            <span />
          </div>
        </div>

        <div className="description">
          Poučení: Dojde-li ke změně údajů uvedených v ohlášení, je poplatník povinen tuto změnu oznámit do 15 dnů ode
          dne, kdy nastala (§ 14a zák. č. 565/1990 o místních poplatcích, ve znění pozdějších předpisů).
        </div>
        <div className="description">Prohlašuji, že jsem veškeré údaje uvedl úplně a správně.</div>
        <div className="date">
          <span className="date-text">V</span>
          <span className="date-input">
            <input className="input-date" />
          </span>
          <span className="date-text">Dne</span>
          <span className="date-input">
            <input className="input-date" type="date" data-date="" data-date-format="DD MMMM YYYY" value={now} />
          </span>
        </div>
        <div className="signature-description">Podpis</div>
        <div className="signature-btn-container">
          <Button variant="outlined" className={"signature-btn"} onClick={this.clear}>
            Smaž podpis
          </Button>
        </div>
        <div className="odeslat">
          <Button variant="outlined" className="odeslat-btn">
            Odeslat
          </Button>
        </div>
      </div>
    );
  }
}

export default Psi;
