import React from "react";
import _ from "lodash";

// // Import regular stylesheet

import { Grid, Form } from "semantic-ui-react";

import { Alert, Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";

import config from "../../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClipboardCheck,
  faExclamationCircle,
  faExclamationTriangle,
  faUserAlt
} from "@fortawesome/free-solid-svg-icons";
import FooterForm from "../../Footer/footerForm";
import Signed from "../../Signed";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";

// Interfaces
export interface AppProps {
  footer: any;
  files: any;
}

export interface AppState {}

class ResultFooter extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
  }

  public renderFiles = (files: any) => {
    if (files) {
      return _.map(files, (file: any, key: any) => {
        return (
          <div key={key}>
            <a href={`${config.API}/${file.file}`}>{file.file_name}</a>
          </div>
        );
      });
    }
  };

  public render() {
    const footer = this.props.footer;
    const files = this.props.files;

    return (
      <div>
        <fieldset className={"mt-5"}>
          <legend>
            <FontAwesomeIcon icon={faUserAlt} className={"mr-2"} /> Podávající
          </legend>

          <div className={"form-group align-items-center required row mt-4"}>
            <label htmlFor="podavajici_jmeno" className="col-lg-3 col-form-label text-lg-right">
              Jméno
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="podavajici_jmeno"
                  name={"podavajici_jmeno"}
                  value={footer.Podavajici.podavajici_jmeno}
                  required
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center required row mt-4"}>
            <label htmlFor="podavajici_prijmeni" className="col-lg-3 col-form-label text-lg-right">
              Příjmení
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="podavajici_prijmeni"
                  name={"podavajici_prijmeni"}
                  value={footer.Podavajici.podavajici_prijmeni}
                  required
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center required row mt-4"}>
            <label htmlFor="podavajici_telefon" className="col-lg-3 col-form-label text-lg-right">
              Kontaktní telefon
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="tel"
                  className="form-control"
                  aria-describedby="kontaktTelefonNapoveda"
                  id="podavajici_telefon"
                  error={footer.Podavajici.numberError}
                  name={"podavajici_telefon"}
                  value={footer.Podavajici.podavajici_telefon}
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center required row mt-4"}>
            <label htmlFor="podavajici_email" className="col-lg-3 col-form-label text-lg-right">
              Kontaktní email
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="podavajici_email"
                  name={"podavajici_email"}
                  value={footer.Podavajici.podavajici_email}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset className={files.length ? "mt-5" : "d-none"}>
          <legend>
            <FontAwesomeIcon icon={faFile} className={"mr-2"} />
            Přílohy
          </legend>
          <div className={"text-center"}>
            {files.map((file: any, index: any) => (
              <a href={`${config.API}/${file.file}`} key={index}>
                <Alert color={"dark"} data-id={index}>
                  {file.file_name}
                </Alert>
              </a>
            ))}
          </div>
        </fieldset>

        <fieldset className="mt-5 text-muted">
          <legend>
            <FontAwesomeIcon icon={faExclamationTriangle} className={"mr-2"} /> Poučení
          </legend>
          Dojde-li ke změně údajů uvedených v ohlášení, je poplatník povinen tuto změnu oznámit do 15 dnů ode dne, kdy
          nastala (§ 14a zák. č. 565/1990 o místních poplatcích, ve znění pozdějších předpisů).
        </fieldset>

        <fieldset className={"mt-5 mb-5"}>
          <legend>
            <FontAwesomeIcon icon={faCheck} className={"mr-2"} /> Potvrzení a odeslání
          </legend>
          <div className={"form-group row mt-4"}>
            <label htmlFor={"podpis"} className={"col-lg-1 col-form-label text-lg-right"}>
              V
            </label>
            <div className="col-lg-5">
              <div className="form-field-wrap">
                <input
                  className="form-control"
                  id="podpis"
                  name={"footer_misto"}
                  type="text"
                  value={footer.Misto.footer_misto}
                  aria-describedby="podpisNapoveda"
                  readOnly={true}
                />
                <small id="podpisNapoveda" className="form-text text-muted">
                  Název obce
                </small>
              </div>
            </div>
            <label htmlFor={"den"} className={"col-lg-1 col-form-label text-lg-right"}>
              Dne
            </label>
            <div className="col-lg-5">
              <div className="form-field-wrap">
                <input
                  className="form-control"
                  id="den"
                  name={"footer_datum"}
                  type="text"
                  value={footer.Misto.footer_datum}
                  aria-describedby="den"
                  readOnly={true}
                />
                <small id="den" className="form-text text-muted">
                  Datum
                </small>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}

export default ResultFooter;
