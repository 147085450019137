const environment = {
    url: process.env.APP_URL,
    port: 8080,
    host: process.env.APP_URL,
    env: process.env.APP_ENV,
    graphql: {
        serverURI: `${process.env.APP_API_URL}/graphql`,
    },
    fetch: {
        submitForm: `${process.env.APP_API_URL}/form/post/`
    },
    API:  process.env.APP_API_URL,
};


const app_configuration = environment;
export type App_configuration = typeof app_configuration;

export default app_configuration;