import React from "react";

// Import Local storage
import StoreLocal from "../../libs/LocalStorage";

// Semantic UI
import { Input, Grid, Form } from "semantic-ui-react";

// Import regular stylesheet

// Interfaces

export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
  getFromLocalStorage: any;
}

export interface AppProps {
  updateData: any;
  id: string;
}

export interface AppState {
  footer_misto: string;
  footer_datum: string;
}

class FooterForm extends React.Component<AppProps, AppState> {
  private storeLocal: LocalStorage = new StoreLocal();
  private date: Date = new Date(Date.now());
  private data: any = JSON.parse(this.storeLocal.getFromLocalStorage(`${this.props.id}`)) || {};

  constructor(props: AppProps) {
    super(props);
    this.state = {
      footer_misto: this.data.footer_misto || "",
      footer_datum:
        this.data.footer_datum || `${this.date.getDate()}.${this.date.getMonth() + 1}.${this.date.getUTCFullYear()}`
    };
  }

  public componentDidMount = async () => {
    try {
      await this.props.updateData(this.state);
    } catch (e) {
      console.log("Error getting the data from memory", e);
    }
  };

  private onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    await this.setState({ footer_misto: e.target.value });
    await this.props.updateData(this.state);

    /** Store component to the cache, according to component ID */
    await this.storeLocal.saveToLocalStorage(`${this.props.id}`, `${JSON.stringify(this.state)}`);
  };

  public render() {
    return (
      <div className={"form-group row mt-4"}>
        <label htmlFor={"podpis"} className={"col-lg-1 col-form-label text-lg-right"}>
          V
        </label>
        <div className="col-lg-5">
          <div className="form-field-wrap">
            <input
              className="form-control"
              id="podpis"
              name={"footer_misto"}
              type="text"
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.footer_misto}
              aria-describedby="podpisNapoveda"
            />
            <small id="podpisNapoveda" className="form-text text-muted">
              Název obce
            </small>
          </div>
        </div>
        <label htmlFor={"den"} className={"col-lg-1 col-form-label text-lg-right"}>
          Dne
        </label>
        <div className="col-lg-5">
          <div className="form-field-wrap">
            <input
              className="form-control"
              id="den"
              name={"footer_datum"}
              onChange={() => {}}
              type="text"
              value={this.state.footer_datum}
              aria-describedby="den"
              readOnly={true}
              required
            />
            <small id="den" className="form-text text-muted">
              Datum
            </small>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterForm;

{
  /* <div className={styles.cellx}>
<div className={styles.cellc}>
    <span>V&nbsp;</span>
    <span className={styles.cell}>
        <Input name={'footer_misto'} type='text' onChange={(e) => {this.onChange(e); }} value={this.state.footer_misto}  />
    </span>
</div>
<div className={styles.celld}>
    <span>Dne&nbsp;</span>
    <span className={styles.cell}>
        <Input name={'footer_datum'} onChange={() => {}} type='text' value={this.state.footer_datum} />
    </span>
</div>
</div>   */
}
