import React from "react";

// // Import regular stylesheet

// Import Local storage
import StoreLocal from "../../../libs/LocalStorage";
import { createSourceEventStream } from "graphql";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";

// Interfaces
export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
  getFromLocalStorage: any;
}
export interface AppProps {
  officeId: string;
  prototypeId: string;
  componentId: string;
  data: Object;
  updateData(offficeId: string, prototypeId: string, componentId: string, componentName: string, data: Object): void;
}
export interface AppState {
  podavajici_jmeno: string;
  podavajici_prijmeni: string;
  podavajici_telefon: string;
  podavajici_email: string;
  nameError: boolean;
  surnameError: boolean;
  numberError: boolean;
  emailError: boolean;
  data: Object;
}

class FormPodavajici extends React.Component<AppProps, AppState> {
  private storeLocal: LocalStorage = new StoreLocal();
  /** ID for local storage */
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);
  private componentName = "form_podavajici";
  constructor(props: AppProps) {
    super(props);
    this.state = {
      podavajici_jmeno: "",
      podavajici_prijmeni: "",
      podavajici_telefon: "",
      podavajici_email: "",
      nameError: false,
      surnameError: false,
      numberError: false,
      emailError: false,
      data: {}
    };
    this.onChange = this.onChange.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.storeLocal.getFromLocalStorage(this.id));
    if (data) {
      await this.setState({ podavajici_jmeno: data.podavajici_jmeno });
      await this.setState({ podavajici_prijmeni: data.podavajici_prijmeni });
      await this.setState({ podavajici_telefon: data.podavajici_telefon });
      await this.setState({ podavajici_email: data.podavajici_email });
    }
    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.currentTarget.name === "podavajici_jmeno") {
      const name: any = e.target.value;
      const regexp = new RegExp("([a-zA-Z]{3,30}s*)+");
      if (regexp.test(name)) {
        this.setState({ nameError: false });
        this.state.data["error"] = false;
      } else {
        this.setState({ nameError: true });
        this.state.data["error"] = true;
      }
      this.setState({ podavajici_jmeno: e.currentTarget.value });
      this.state.data["podavajici_jmeno"] = e.currentTarget.value;
    }
    if (e.currentTarget.name === "podavajici_prijmeni") {
      const surname: any = e.target.value;
      const regexp = new RegExp("[a-zA-Z]{3,30}");
      if (regexp.test(surname)) {
        this.setState({ surnameError: false });
        this.state.data["error"] = false;
      } else {
        this.setState({ surnameError: true });
        this.state.data["error"] = true;
      }
      this.setState({ podavajici_prijmeni: e.currentTarget.value });
      this.state.data["podavajici_prijmeni"] = e.currentTarget.value;
    }

    if (e.currentTarget.name === "podavajici_telefon") {
      const numberPhone: any = e.target.value;
      const regexp = new RegExp("^[+]?[()/0-9. -]{9,}$");
      if (regexp.test(numberPhone)) {
        this.setState({ numberError: false });
        this.state.data["error"] = false;
      } else {
        this.setState({ numberError: true });
        this.state.data["error"] = true;
      }
      this.setState({ podavajici_telefon: numberPhone });
      this.state.data["podavajici_telefon"] = e.currentTarget.value;
    }
    if (e.currentTarget.name === "podavajici_email") {
      const email: any = e.target.value;
      const regexp = new RegExp("[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}");
      if (regexp.test(email)) {
        this.setState({ emailError: false });
        this.state.data["error"] = false;
      } else {
        this.setState({ emailError: true });
        this.state.data["error"] = true;
      }
      this.setState({ podavajici_email: email });
      this.state.data["podavajici_email"] = e.currentTarget.value;
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state.data
    );

    /** Store component to the cache, according to component ID */
    this.storeLocal.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state.data)}`);
  };

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faUserAlt} className={"mr-2"} /> Podávající
        </legend>

        <div className={"form-group required row mt-4"}>
          <label htmlFor="podavajici_jmeno" className="col-lg-3 col-form-label text-lg-right">
            Jméno
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="podavajici_jmeno"
                error={this.state.nameError}
                name={"podavajici_jmeno"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.podavajici_jmeno}
                required
              />
              <div className="invalid-feedback">Vyplňte jméno</div>
            </div>
          </div>
        </div>

        <div>
          <span>Jméno</span>
          <span>
            <div>
              <Input
                error={this.state.nameError}
                required
                name={"podavajici_jmeno"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.podavajici_jmeno}
              />
            </div>
          </span>
        </div>

        <div>
          <span>Příjmení</span>
          <span>
            <div>
              <Input
                error={this.state.surnameError}
                required
                name={"podavajici_prijmeni"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.podavajici_prijmeni}
              />
            </div>
          </span>
        </div>

        <div>
          <span>Kontaktní telefon</span>
          <span>
            <div>
              <Input
                error={this.state.numberError}
                required
                name={"podavajici_telefon"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.podavajici_telefon}
              />
            </div>
          </span>
        </div>

        <div>
          <span>Kontaktní email</span>
          <span>
            <div>
              <Input
                error={this.state.emailError}
                required
                name={"podavajici_email"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.podavajici_email}
              />
            </div>
          </span>
        </div>
      </fieldset>
    );
  }
}

export default FormPodavajici;
