import React from "react";
import DogDetail from "./DogDetail";

const Pdf = ({ data: { psi } }) => (
  <React.Fragment>
    <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
      Popis psů
    </div>
    {(!psi || psi.length === 0) && <p>žádní psi</p>}
    {psi && psi.length > 0 && psi.map((dog, index) => <DogDetail key={index} jmeno={`${index + 1}. pes`} {...dog} />)}
  </React.Fragment>
);

export default Pdf;
