import React, { SyntheticEvent } from "react";
import _ from "lodash";
import { toast, ToastContainer } from "react-toastify";

// GraphQL query
import { Query } from "react-apollo";
import BOUGHT_FORM_QUERY from "../../queries/bought_forms";

/** Import config */
import config from "../../../config/config";

// Form Components
import Header from "../Header";
import Footer from "../Footer";
import RenderComponent from "../RenderComponent";
import { AvForm } from "availity-reactstrap-validation";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck, faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import jws from "jws";
import "react-toastify/dist/ReactToastify.css";

library.add(faSpinner);

// Type interfaces
import { AppProps, AppState, Data, Variables } from "./types";

export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
  getFromLocalStorage: any;
}

// Import Local storage
import StoreLocal from "../../libs/LocalStorage";
import { Alert } from "reactstrap";
import { Redirect } from "react-router";

class RenderForm extends React.Component<AppProps, AppState> {
  private data: Object = {};
  private componentData: Object = {};
  private poplatnikFilled = false;
  private storeLocal: LocalStorage = new StoreLocal();

  constructor(props: AppProps) {
    super(props);
    this.state = {
      validatorError: false,
      data: {},
      componentData: {},
      signedModal: false,
      unSignedModal: false,
      attachments: [],
      privateKey: "",
      publicKey: "",
      submittingSignedForm: false,
      formResolved: false,
      formDeployed: false,
      footerData: {},
      registryVerified: false,
      redirect: false,
      submitted: false,
      certIssuer: {}
    };
  }

  componentDidMount() {
    this.setState({ attachments: [] });
  }

  validate = async (errors: []) => {
    if (errors.length > 0) {
      await toast("Musíte vyplnit všechna povinná pole", {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        position: "top-right",
        closeOnClick: true
      });
      return true;
    }

    return false;
  };

  private getData(data: Object) {
    let temp: Object = {};
    Object.keys(data).forEach(key => {
      temp = data[`${key}`];
    });
    return temp;
  }

  private constructDeliveryMails = async (unsignedForm: any) => {
    let deliveryMails: any = [];
    const header: any = await this.getData(unsignedForm.header);
    const footer: any = await this.getData(unsignedForm.footer);

    if (footer.Podavajici === null) {
      this.setState({ validatorError: true });
      return;
    }
    const sender: string = footer.Podavajici.podavajici_email;

    if (header) {
      deliveryMails.push(header.registry_email);
    }

    if (sender) {
      deliveryMails.push(sender);
    }

    return deliveryMails;
  };

  private submitForm = async (event: SyntheticEvent, errors: any, values: object) => {
    const uploadFiles = this.state.attachments;
    const formData = new FormData();
    let unsignedForm: any = this.data;

    let deliveryMails: any = await this.constructDeliveryMails(this.data);
    const validatorError = await this.validate(errors);

    if (!validatorError) {
      this.setState({ submitted: true });
      if (this.state.privateKey && this.state.publicKey) {
        this.setState({ submittingSignedForm: true });
        const signedForm = await jws.sign({
          header: { alg: "RS256" },
          payload: unsignedForm,
          privateKey: this.state.privateKey
        });
        formData.append("form", JSON.stringify(signedForm));
        formData.append("bought_form", JSON.stringify(unsignedForm.bought_forms));
        formData.append("pubkey", JSON.stringify(this.state.publicKey));
        formData.append("certIssuer", JSON.stringify(this.state.certIssuer));
        formData.append("delivery_emails", JSON.stringify(deliveryMails));
        if (uploadFiles) {
          uploadFiles.forEach((file: any) => {
            // console.log(file);
            formData.append("files[]", file);
          });
        }
        try {
          // Pokus o odeslani nactenych dat
          const response = await fetch(`${config.fetch.submitForm}`, {
            method: "POST",
            mode: "cors",
            body: formData
          }).then((res: any) => {
            return res;
          });
          if (!response.ok) {
            this.setState({
              submittingSignedForm: false,
              formResolved: true,
              formDeployed: false
            });
            throw Error(response.statusText);
          }
          if (response.status === 200) {
            this.setState({
              submittingSignedForm: false,
              formDeployed: true,
              formResolved: true
            });
            this.storeLocal.clearLocalStorage();
            return await this.handleReload();
          }
          this.setState({ formDeployed: false, formResolved: true });
          throw Error(response.statusText);
        } catch (error) {
          this.setState({
            submittingSignedForm: false,
            formDeployed: false,
            formResolved: true
          });
        }
      } else {
        this.setState({ submittingSignedForm: true });
        formData.append("form", JSON.stringify(unsignedForm));
        formData.append("bought_form", JSON.stringify(unsignedForm.bought_forms));
        formData.append("pubkey", JSON.stringify(""));
        formData.append("certIssuer", JSON.stringify(""));
        formData.append("delivery_emails", JSON.stringify(deliveryMails));
        if (uploadFiles) {
          uploadFiles.forEach((file: any) => {
            formData.append("files[]", file);
          });
        }
        try {
          const response = await fetch(`${config.fetch.submitForm}`, {
            method: "POST",
            mode: "cors",
            body: formData
          }).then((res: any) => {
            return res;
          });
          if (!response.ok) {
            // Chyba odpovedi
            this.setState({ submittingSignedForm: false });
            this.setState({ formResolved: true });
            this.setState({ formDeployed: false });
            throw Error(response.statusText);
          }
          if (response.status === 200) {
            // Odpoved v poradku
            this.setState({ submittingSignedForm: false });
            this.setState({ formResolved: true });
            this.setState({ formDeployed: true });
            this.storeLocal.clearLocalStorage();
            return await this.handleReload();
          }
          this.setState({ formDeployed: true });
          this.setState({ formResolved: true });
          throw Error(response.statusText);
        } catch (error) {
          // Chyba serveru
          this.setState({ submittingSignedForm: false });
          this.setState({ formDeployed: false });
          this.setState({ formResolved: true });
        }
      }

      formData.forEach((value: any, key: any) => {});
    } else {
      return null;
    }
  };

  onKeyPress(event) {
    if (event.which === 13) {
      event.preventDefault();
    }
  }

  handleCertIssuer = async issuerInfo => {
    await this.setState({ certIssuer: issuerInfo });
  };

  handleKeys = async (privkey: any, pubkey: any) => {
    await this.setState({ privateKey: privkey });
    await this.setState({ publicKey: pubkey });
  };
  handleAttachments = async (attachments: any) => {
    await this.setState({ attachments: attachments });
  };

  private updateHeaderData = async (headerId: string, id_bought_forms: string, data: any) => {
    this.data[`${headerId}`] = data;
    this.data["bought_forms"] = id_bought_forms;
  };

  private updateFooterData = async (footerId: string, data: Object) => {
    const footerData: Object = {};
    footerData[`${footerId}`] = data;
    this.data["footer"] = footerData;
  };

  private updateComponentData = (
    officeId: string,
    formId: string,
    componentId: string,
    componentName: string,
    state: Object
  ) => {
    this.componentData[`${componentName}`] = state || "";
    this.data["components"] = this.componentData;
  };

  private handleReload = async () => {
    await setTimeout(() => this.setState({ redirect: true }), 5000);
  };

  public render() {
    const slug_organisation = this.props.match.params.organisation;
    const slug_forms = this.props.match.params.form;
    return (
      <Query<Data, Variables> query={BOUGHT_FORM_QUERY} variables={{ slug_organisation, slug_forms }}>
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div>
                <FontAwesomeIcon icon={faSpinner} />
              </div>
            );
          if (error) return <div>Error loading tha data: {error}</div>;
          const _data: any = data;
          if (_data.bought_form) {
            const form = _data.bought_form.form;
            const id_forms = _data.bought_form.id_forms;
            const id_organisations = _data.bought_form.id_organisations;
            const id_bought_forms = _data.bought_form.id_bought_forms;
            const components = form.components.sort(function(a, b) {
              return parseInt(a.position) - parseInt(b.position);
            });

            if (this.state.redirect) {
              return <Redirect to={{ pathname: `/${slug_organisation}` }} />;
            }

            return (
              <div>
                <ToastContainer />
                <Header
                  id_bought_forms={id_bought_forms}
                  updateData={this.updateHeaderData}
                  id_organisations={id_organisations}
                />
                <div className={!this.state.formResolved ? "mt-5 col-12 d-none" : "mt-5 col-12"}>
                  <Alert
                    color={"success"}
                    className={!this.state.formDeployed ? "d-none" : ""}
                    onClick={this.handleReload}
                  >
                    <h5 className={"alert-heading"}>
                      <FontAwesomeIcon icon={faClipboardCheck} /> Váš formulář byl úspěšně odeslán
                    </h5>
                    <p>
                      Po chvíli budete automaticky přesměrováni. Pokud nebudete přesměrováni do pěti sekund, klikněte{" "}
                      <a href={`/${slug_organisation}`}>sem.</a>
                    </p>
                  </Alert>
                  <Alert
                    color={"danger"}
                    className={this.state.formDeployed ? "d-none" : ""}
                    onClick={this.handleReload}
                  >
                    <h5 className={"alert-heading"}>
                      <FontAwesomeIcon icon={faExclamationCircle} /> Během odesílání formuláře nastala chyba
                    </h5>
                    <p>Klikni pro reload</p>
                  </Alert>
                </div>
                <AvForm
                  className={this.state.formResolved ? "d-none" : "needs-validation"}
                  onKeyPress={this.onKeyPress}
                  onSubmit={this.submitForm}
                >
                  <div className={"row mt-5"}>
                    <div className={"col"}>
                      <div className={"p-3"}>
                        {components.map((component: any, key: any) => {
                          return (
                            <div key={key}>
                              <RenderComponent
                                updateData={this.updateComponentData}
                                data={this.props.data}
                                officeId={""}
                                prototypeId={form.id_forms}
                                componentName={component.name}
                                componentId={component.id_components}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <Footer
                    id_organisations={id_organisations}
                    validatorError={this.state.validatorError}
                    formsId={id_forms}
                    footerId={form.footer.id_components}
                    updateData={this.updateFooterData}
                    formResolved={this.state.formResolved}
                    formDeployed={this.state.formDeployed}
                    certIssuer={this.handleCertIssuer}
                    sendKeysToSubmit={this.handleKeys}
                    submittingSignedForm={this.state.submittingSignedForm}
                    sendAttachmentsToSubmit={this.handleAttachments}
                    submitted={this.state.submitted}
                  />
                </AvForm>
              </div>
            );
          } else {
            return <div>Form not found</div>;
          }
        }}
      </Query>
    );
  }
}

export default RenderForm;
