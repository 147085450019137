import * as React from "react";
import VyjadreniProps from "./VyjadreniProps";
import { VyjadreniState } from "./VyjadreniState";
import { LocalStorage } from "../../../interfaces/LocalStorage";
import StoreLocal from "../../../libs/LocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons/faBook";
import { Input } from "reactstrap";

export default class FormProstrediVyjadreni extends React.Component<VyjadreniProps, VyjadreniState> {
  private componentName = "form_prostredi_vyjadreni";
  private localStorage: LocalStorage = new StoreLocal();
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);

  constructor(props: VyjadreniProps) {
    super(props);

    this.state = {
      akce: "",
      katastralni_uzemi: "",
      vratit_dokumentaci: false,
      vyjadreni_osobne: false,
      vyjadreni_zaslat: false,
      zaslat_na_urad: false
    };

    this.onChange = this.onChange.bind(this);
    this.changeCheckbox = this.changeCheckbox.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.localStorage.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  private onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    await this.setState(({
      [e.currentTarget.name]: e.currentTarget.value
    } as unknown) as { [K in keyof VyjadreniState]: VyjadreniState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  private async changeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    await this.setState(({
      [e.currentTarget.name]: e.target.checked
    } as unknown) as { [K in keyof VyjadreniState]: VyjadreniState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  }

  render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faBook} className={"mr-2"} /> Vyjádření k projektové dokumentaci
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="akce" className="col-lg-3 col-form-label text-lg-right">
            Akce
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="akce"
                name={"akce"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.akce}
                placeholder={"Akce"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="katastralni_uzemi" className="col-lg-3 col-form-label text-lg-right">
            Katastrální území
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="katastralni_uzemi"
                name={"katastralni_uzemi"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.katastralni_uzemi}
                placeholder={"Katastrální území"}
              />
            </div>
          </div>
        </div>

        <div className="form-group row justify-content-md-center pt-3">
          <div className="row col-lg-6">
            <div className="col-12 custom-control custom-checkbox">
              <input
                type="checkbox"
                id="vyjadreni_zaslat"
                name="vyjadreni_zaslat"
                className="custom-control-input"
                value={+this.state.vyjadreni_zaslat}
                checked={this.state.vyjadreni_zaslat}
                onChange={e => this.changeCheckbox(e)}
              />
              <label className="custom-control-label" htmlFor="vyjadreni_zaslat">
                vyjádření zaslat na adresu žadatele
              </label>
            </div>
            <div className="col-12 custom-control custom-checkbox">
              <input
                type="checkbox"
                id="vyjadreni_osobne"
                name="vyjadreni_osobne"
                className="custom-control-input"
                checked={this.state.vyjadreni_osobne}
                onChange={e => this.changeCheckbox(e)}
              />
              <label className="custom-control-label" htmlFor="vyjadreni_osobne">
                vyjádření si převezmu osobně
              </label>
            </div>
            <div className="col-12 custom-control custom-checkbox">
              <input
                type="checkbox"
                id="vratit_dokumentaci"
                name="vratit_dokumentaci"
                className="custom-control-input"
                checked={this.state.vratit_dokumentaci}
                onChange={e => this.changeCheckbox(e)}
              />
              <label className="custom-control-label" htmlFor="vratit_dokumentaci">
                požaduji vrátit projektovou dokumentaci
              </label>
            </div>
            <div className="col-12 custom-control custom-checkbox">
              <input
                type="checkbox"
                id="zaslat_na_urad"
                name="zaslat_na_urad"
                className="custom-control-input"
                checked={this.state.zaslat_na_urad}
                onChange={e => this.changeCheckbox(e)}
              />
              <label className="custom-control-label" htmlFor="zaslat_na_urad">
                vyjádření zaslat s projektovou dokumentací na stavební úřad
              </label>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
