import "../../styles/main.css";

require("bootstrap");
require("jquery");
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import RenderPdf from "../RenderPdf";

// GraphQL Queryy
import { Query } from "react-apollo";
import ORGANISATIONS_QUERY from "../../queries/organisations";

import Organisation from "../Organisation";
import RenderResult from "../RenderResult";
import RenderForm from "../RenderForm";
import { Container } from "reactstrap";

export interface Data {
  organisations: {
    id_organisations: string;
    name: string;
    slug: string;
  };
}

const NotFound = () => <div>Page not found</div>;

class App extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Query<Data> query={ORGANISATIONS_QUERY} pollInterval={60000}>
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div className={"container"}>
                <div className="spinner-grow text-primary" style={{ width: 20, height: 20 }} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            );
          if (error) return <div>Chyba při nahrávání dat: {error.message}</div>;
          return (
            <BrowserRouter>
              <Container>
                <Switch>
                  <Route exact={true} path="/" render={() => (window.location.href = "https://vhodne-uverejneni.cz")} />
                  <Route exact={true} path="/form/:id" component={RenderResult} />
                  <Route exact={true} path="/pdf/:id" component={RenderPdf} />
                  <Route exact={true} path="/:organisation" component={Organisation} />
                  <Route exact={true} path="/:organisation/:form" component={RenderForm} />
                  <NotFound />
                </Switch>
              </Container>
            </BrowserRouter>
          );
        }}
      </Query>
    );
  }
}

export default App;
