import React from "react";
import { OsobaProps } from "./OsobaProps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";

export default class FormKomunikaceOsoba extends React.Component<OsobaProps> {
  constructor(props: OsobaProps) {
    super(props);
  }

  render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faUserTie} className={"mr-2"} /> Osoba zodpovědná za zvláštní užívání
        </legend>
        <div className="form-group row justify-content-md-center pt-3">
          <div className="row col-lg-6">
            <div className="col-12 custom-control custom-checkbox">
              <input
                type="checkbox"
                id="jina_osoba"
                name="jina_osoba"
                className="custom-control-input"
                value={+this.props.data.jina_osoba}
                checked={this.props.data.jina_osoba}
                readOnly={true}
              />
              <label className="custom-control-label" htmlFor="jina_osoba">
                Osoba zodpovědná je jiná než pořadatel
              </label>
            </div>
          </div>
        </div>
        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="jmeno" className="col-lg-3 col-form-label text-lg-right">
            Jméno
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="jmeno"
                name={"jmeno"}
                value={this.props.data.jina_osoba ? this.props.data.jmeno : ""}
                disabled={!this.props.data.jina_osoba}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prijmeni" className="col-lg-3 col-form-label text-lg-right">
            Příjmení
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="prijmeni"
                name={"prijmeni"}
                value={this.props.data.jina_osoba ? this.props.data.prijmeni : ""}
                disabled={!this.props.data.jina_osoba}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="adresa" className="col-lg-3 col-form-label text-lg-right">
            Adresa
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="adresa"
                name={"adresa"}
                value={this.props.data.jina_osoba ? this.props.data.adresa : ""}
                disabled={!this.props.data.jina_osoba}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="telefon" className="col-lg-3 col-form-label text-lg-right">
            Telefon
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="telefon"
                name={"telefon"}
                value={this.props.data.jina_osoba ? this.props.data.telefon : ""}
                disabled={!this.props.data.jina_osoba}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
