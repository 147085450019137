import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

// // Import regular stylesheet

// Interfaces
export interface AppProps {}

class FormPouceni extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
  }

  public render() {
    return (
      <fieldset className="mt-5 text-muted">
        <legend>
          <FontAwesomeIcon icon={faExclamationTriangle} className={"mr-2"} /> Poučení
        </legend>
        Dojde-li ke změně údajů uvedených v ohlášení, je poplatník povinen tuto změnu oznámit do 15 dnů ode dne, kdy
        nastala (§ 14a zák. č. 565/1990 o místních poplatcích, ve znění pozdějších předpisů).
      </fieldset>
    );
  }
}

export default FormPouceni;
