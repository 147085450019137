import React from "react";
import HeaderProps from "./HeaderProps";
import styled from "styled-components";

const HeaderTitle = styled.h1`
  padding-top: 30px;
  @media print {
    padding-top: 0px;
  }
`;

const HeaderPdf = ({ data: { organisation }, formData, issuer }: any) => {
  const { name, inr } = formData.organisationData;
  const date = formData.created / 1000;
  console.log(issuer);
  return (
    <React.Fragment>
      <HeaderTitle>Elektronické podání</HeaderTitle>
      <p
        style={{
          marginTop: 30,
          marginBottom: 3,
          fontWeight: 700,
          fontSize: 18
        }}
      >
        {organisation.name}, {organisation.street_name}, {organisation.city} {organisation.zip_code}, IČ: {inr}
      </p>
      <p style={{ marginBottom: 3 }}>
        <b>Formulář:</b> {formData.form.name}
      </p>
      <p style={{ marginBottom: 3 }}>
        <b>Místo podání:</b> {formData.organisationData.www}
      </p>
      <p style={{ marginBottom: 3 }}>
        <b>Podatelna:</b> {organisation.registry_email}
      </p>
      <p style={{ marginBottom: 3, paddingBottom: 20 }}>
        Datum a čas podání: {new Date(Math.round(date * 1000)).toLocaleString("cs")}{" "}
      </p>
      <p style={{ paddingBottom: 20 }}>
        <b>Identita autora podpisu:</b>{" "}
        {issuer
          ? `sériové číslo: ${issuer.serialNumber.value},  vystavitel ${
              issuer.commonName ? issuer.commonName.value : ""
            }, ${issuer.organization ? issuer.organization.value : ""}, ${
              issuer.locality ? issuer.locality.value : ""
            } ${issuer.country ? issuer.country.value : ""}`
          : `NEPODEPSÁNO`}
      </p>{" "}
      <p style={{ paddingBottom: 20 }}>
        <i>
          Ověřeno systémem e-forms, společnosti QCM, s.r.o. Heršpická 813/5, 639 00 Brno,
          IČO: 26262525.{" "}
        </i>
      </p>
      <div style={{ marginBottom: "30px", borderBottom: "1px solid black" }}></div>
    </React.Fragment>
  );
};

export default HeaderPdf;
