import React from "react";

// // Import regular stylesheet

// Import Local storage
import StoreLocal from "../../libs/LocalStorage";
import { Grid, Form } from "semantic-ui-react";
import { Input } from "reactstrap";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faUser, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { AvField } from "availity-reactstrap-validation";

library.add(faAsterisk, faUserAlt);

// Interfaces
export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
  getFromLocalStorage: any;
}

export interface AppProps {
  prototypeId: string;
  footerId: string;
  data: Object;

  updateData(data: Object): void;
}

export interface AppState {
  podavajici_jmeno: string;
  podavajici_prijmeni: string;
  podavajici_telefon: string;
  podavajici_email: string;
  nameError: boolean;
  surnameError: boolean;
  numberError: boolean;
  emailError: boolean;
  mainError: boolean;
}

class FormPodavajici extends React.PureComponent<AppProps, AppState> {
  private storeLocal: LocalStorage = new StoreLocal();
  private componentName = "form_podavajici";
  private mainError: any = true;

  /** ID for local storage */
  private id: string = this.props.prototypeId.concat(this.props.footerId, this.componentName);

  constructor(props: AppProps) {
    super(props);
    this.state = {
      podavajici_jmeno: "",
      podavajici_prijmeni: "",
      podavajici_telefon: "",
      podavajici_email: "",
      nameError: true,
      surnameError: true,
      numberError: true,
      emailError: true,
      mainError: true
    };
    this.changeName = this.changeName.bind(this);
    this.changeSurname = this.changeSurname.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.storeLocal.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    await this.props.updateData(data);
  };

  private updateMainError = () => {
    let errors: any = {};
    errors.nameError = this.state.nameError;
    errors.surnameError = this.state.surnameError;
    errors.numberError = this.state.numberError;
    errors.emailError = this.state.emailError;
    const e = Object.keys(errors)
      .filter(key => errors[key])
      .join(",").length;
    if (e > 0) {
      this.setState({ mainError: true });
      this.mainError = true;
    } else {
      this.setState({ mainError: false });
      this.mainError = false;
    }
  };

  public changeName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    /** NAME VALIDATOR */

    if (e.currentTarget.name === "podavajici_jmeno") {
      const name: any = e.currentTarget.value;
      const regexp = new RegExp("(^[a-zA-ZáčďéěíňóřšťůúýžľôöäüÁČĎÉĚÍŇÓŘŠŤŮÚÝŽĽÄÔÜÖ .' `]{2,30}$)+");
      if (regexp.test(name)) {
        this.setState({ nameError: false });
      } else {
        this.setState({ nameError: true });
      }
    }

    await this.setState({ podavajici_jmeno: e.currentTarget.value });
    await this.props.updateData(this.state);

    /** Store component to the cache, according to component ID */
    await this.storeLocal.saveToLocalStorage(this.id, `${JSON.stringify(this.state)}`);
  };

  public changeSurname = async (e: React.ChangeEvent<HTMLInputElement>) => {
    /** SURNAME VALIDATOR */

    if (e.currentTarget.name === "podavajici_prijmeni") {
      const surname: any = e.currentTarget.value;
      const regexp = new RegExp("(^[a-zA-ZáčďéěíňóřšťůúýžľôöäüÁČĎÉĚÍŇÓŘŠŤŮÚÝŽĽÄÔÜÖ .'-`]{2,30}$)+");
      if (regexp.test(surname)) {
        this.setState({ surnameError: false });
      } else {
        this.setState({ surnameError: true });
      }
    }

    await this.setState({ podavajici_prijmeni: e.currentTarget.value });
    await this.props.updateData(this.state);

    /** Store component to the cache, according to component ID */
    await this.storeLocal.saveToLocalStorage(this.id, `${JSON.stringify(this.state)}`);
  };

  public changePhone = async (e: React.ChangeEvent<HTMLInputElement>) => {
    /** PHONE VALIDATOR */

    if (e.currentTarget.name === "podavajici_telefon") {
      const numberPhone: any = e.currentTarget.value;
      const regexp = new RegExp("^[+]?[()/0-9. -]{9,}$");
      if (regexp.test(numberPhone)) {
        this.setState({ numberError: false });
      } else {
        this.setState({ numberError: true });
      }
    }

    await this.setState({ podavajici_telefon: e.currentTarget.value });
    await this.props.updateData(this.state);

    /** Store component to the cache, according to component ID */
    await this.storeLocal.saveToLocalStorage(this.id, `${JSON.stringify(this.state)}`);
  };

  public changeEmail = async (e: React.ChangeEvent<HTMLInputElement>) => {
    /** EMAIL VALIDATOR */

    if (e.currentTarget.name === "podavajici_email") {
      const email: any = e.currentTarget.value;
      const regexp = new RegExp("[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}");
      if (regexp.test(email)) {
        this.setState({ emailError: false });
      } else {
        this.setState({ emailError: true });
      }
    }

    await this.setState({ podavajici_email: e.currentTarget.value });
    await this.props.updateData(this.state);

    /** Store component to the cache, according to component ID */
    await this.storeLocal.saveToLocalStorage(this.id, `${JSON.stringify(this.state)}`);
  };

  public render() {
    this.updateMainError();

    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faUser} className={"mr-2"} /> Podávající
        </legend>

        <div className={"form-group align-items-center required row mt-4"}>
          <label htmlFor="podavajici_jmeno" className="col-lg-3 col-form-label text-lg-right">
            Jméno
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <AvField
                type="text"
                className="form-control"
                id="podavajici_jmeno"
                error={this.state.nameError}
                placeholder={"Jméno"}
                name={"podavajici_jmeno"}
                trueValue={"is-valid"}
                onChange={e => {
                  this.changeName(e);
                }}
                value={this.state.podavajici_jmeno}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Prosím zadejte jméno"
                  },
                  pattern: {
                    value: "^[A-Za-záčďéěíňóřšťůúýžľôöäüÁČĎÉĚÍŇÓŘŠŤŮÚÝŽĽÄÔÜÖ -']+$",
                    errorMessage: "Použijte pouze povolené znaky"
                  },
                  minLength: {
                    value: 2,
                    errorMessage: "Jméno musí mít minimálně 2 znaky"
                  },
                  maxLength: { value: 16 }
                }}
                autoComplete={"off"}
                required
              />
              <div className="invalid-feedback">Vyplňte jméno</div>
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center required row mt-4"}>
          <label htmlFor="podavajici_prijmeni" className="col-lg-3 col-form-label text-lg-right">
            Příjmení
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <AvField
                type="text"
                className="form-control"
                id="podavajici_prijmeni"
                error={this.state.surnameError}
                placeholder={"Příjmení"}
                name={"podavajici_prijmeni"}
                onChange={e => {
                  this.changeSurname(e);
                }}
                value={this.state.podavajici_prijmeni}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Prosím zadejte příjmení"
                  },
                  pattern: {
                    value: "^[A-Za-záčďéěíňóřšťůúýžľôöäüÁČĎÉĚÍŇÓŘŠŤŮÚÝŽĽÄÔÜÖ -']+$",
                    errorMessage: "Použijte pouze povolené znaky"
                  },
                  minLength: {
                    value: 2,
                    errorMessage: "Příjmení musí mít minimálně 2 znaky"
                  },
                  maxLength: { value: 16 }
                }}
                autoComplete={"off"}
                required
              />
              <div className="invalid-feedback">Vyplňte příjmení</div>
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center required row mt-4"}>
          <label htmlFor="podavajici_telefon" className="col-lg-3 col-form-label text-lg-right">
            Kontaktní telefon
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <AvField
                type="text"
                className="form-control"
                aria-describedby="kontaktTelefonNapoveda"
                id="podavajici_telefon"
                error={this.state.numberError}
                placeholder={"--- --- ---"}
                name={"podavajici_telefon"}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Prosím zadejte telefonní číslo"
                  },
                  pattern: {
                    value: "^(\\+[0-9]{0,3})?\\s{0,1}[1-9][0-9]{2}?\\s{0,1}[0-9]{3}?\\s{0,1}[0-9]{3}$",
                    errorMessage: "Formát není validní"
                  },
                  minLength: {
                    value: 9,
                    errorMessage: "Telefon musí mít minimálně 9 znaků"
                  },
                  maxLength: { value: 16 }
                }}
                onChange={e => {
                  this.changePhone(e);
                }}
                value={this.state.podavajici_telefon}
                autoComplete={"off"}
                required
              />
              <div className="invalid-feedback">Vyplňte telefon</div>
              <small id="kontaktTelefonNapoveda" className="form-text text-muted">
                V rámci České Republiky nemusíte zadávat mezinárodní předvolbu
              </small>
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center required row mt-4"}>
          <label htmlFor="podavajici_email" className="col-lg-3 col-form-label text-lg-right">
            Kontaktní email
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <AvField
                type="email"
                className={"form-control"}
                id="podavajici_email"
                validate={{
                  email: {
                    value: true,
                    errorMessage: "Prosím zadejte email ve správném tvaru"
                  },
                  required: {
                    value: true,
                    errorMessage: "Prosím vyplňte email"
                  }
                }}
                error={this.state.emailError}
                placeholder={"Kontaktní email"}
                name={"podavajici_email"}
                onChange={e => {
                  this.changeEmail(e);
                }}
                value={this.state.podavajici_email}
                autoComplete={"off"}
                required
              />
              <div className="invalid-feedback">Vyplňte email</div>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default FormPodavajici;
