import React from "react";

import StoreLocal from "../../../libs/LocalStorage";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons/faMoneyBillAlt";

// Interfaces
export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
  getFromLocalStorage: any;
}
export interface AppProps {
  officeId: string;
  prototypeId: string;
  componentId: string;
  data: Object;
  updateData(offficeId: string, prototypeId: string, componentId: string, componentName: string, data: Object): void;
}
export interface AppState {
  prostranstvi_priznani_dne: any;
  prostranstvi_priznani_m2: any;
  prostranstvi_priznani_povinnost: any;
  prostranstvi_priznani_povinnost_dny: any;
  prostranstvi_priznani_sazba: any;
  prostranstvi_priznani_poplatek: any;
}

class FormProstranstviPriznani extends React.Component<AppProps, AppState> {
  private storeLocal: LocalStorage = new StoreLocal();
  /** ID for local storage */
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);
  private componentName = "form_prostranstvi_priznani";

  constructor(props: AppProps) {
    super(props);
    this.state = {
      prostranstvi_priznani_dne: "",
      prostranstvi_priznani_m2: "",
      prostranstvi_priznani_povinnost: "",
      prostranstvi_priznani_povinnost_dny: "",
      prostranstvi_priznani_sazba: "",
      prostranstvi_priznani_poplatek: ""
    };
    this.onChange = this.onChange.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.storeLocal.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  public onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    await this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    } as { [K in keyof AppState]: AppState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.storeLocal.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faMoneyBillAlt} className={"mr-2"} /> Podávám přiznání k platbě místního poplatku za
          užívání veřejného prostranství na místo a účel užívání
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prostranstvi_priznani_dne" className="col-lg-3 col-form-label text-lg-right">
            Dohodnuto dne
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="prostranstvi_priznani_dne"
                name={"prostranstvi_priznani_dne"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.prostranstvi_priznani_dne}
                placeholder={"Dohodnuto dne"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prostranstvi_priznani_m2" className="col-lg-3 col-form-label text-lg-right">
            Počet m<sup>2</sup>
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="number"
                min={0}
                className="form-control"
                id="prostranstvi_priznani_m2"
                name={"prostranstvi_priznani_m2"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.prostranstvi_priznani_m2}
                placeholder={"Počet m2"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prostranstvi_priznani_povinnost" className="col-lg-3 col-form-label text-lg-right">
            Poplatková povinnost od – do
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="prostranstvi_priznani_povinnost"
                name={"prostranstvi_priznani_povinnost"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.prostranstvi_priznani_povinnost}
                placeholder={"Poplatková povinnost od – do"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prostranstvi_priznani_povinnost_dny" className="col-lg-3 col-form-label text-lg-right">
            Počet dnů
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="number"
                min={0}
                className="form-control"
                id="prostranstvi_priznani_povinnost_dny"
                name={"prostranstvi_priznani_povinnost_dny"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.prostranstvi_priznani_povinnost_dny}
                placeholder={"Počet dnů"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prostranstvi_priznani_sazba" className="col-lg-3 col-form-label text-lg-right">
            Sazba poplatku za 1 den (dle OZV, čl. 15)
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="prostranstvi_priznani_sazba"
                name={"prostranstvi_priznani_sazba"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.prostranstvi_priznani_sazba}
                placeholder={"Sazba poplatku za 1 den (dle OZV, čl. 15)"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prostranstvi_priznani_poplatek" className="col-lg-3 col-form-label text-lg-right">
            Poplatek celkem
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="prostranstvi_priznani_poplatek"
                name={"prostranstvi_priznani_poplatek"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.prostranstvi_priznani_poplatek}
                placeholder={"Poplatek celkem"}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default FormProstranstviPriznani;
