import React from "react";

// Import Local storage
import StoreLocal from "../../../libs/LocalStorage";

// Interfaces
export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
  getFromLocalStorage: any;
}
export interface AppProps {
  officeId: string;
  prototypeId: string;
  componentId: string;
  data: Object;
  updateData(offficeId: string, prototypeId: string, componentId: string, data: Object): void;
}
export interface AppState {
  psi_drzitel_name: string;
  psi_drzitel_rc: string;
  psi_drzitel_adresa: string;
  psi_drzitel_dorucovaci_adresa: string;
  psi_drzitel_pocet_bytu: string;
  psi_drzitel_duchodce: string;
  psi_drzitel_stari_psa: string;
  psi_drzitel_datum_drzeni: string;
}

class FormPsiDrzitel extends React.Component<AppProps, AppState> {
  private storeLocal: LocalStorage = new StoreLocal();

  /** ID for local storage */
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);

  constructor(props: AppProps) {
    super(props);
    this.state = {
      psi_drzitel_name: "",
      psi_drzitel_rc: "",
      psi_drzitel_adresa: "",
      psi_drzitel_dorucovaci_adresa: "",
      psi_drzitel_pocet_bytu: "",
      psi_drzitel_duchodce: "",
      psi_drzitel_stari_psa: "",
      psi_drzitel_datum_drzeni: ""
    };
    this.onChange = this.onChange.bind(this);
  }

  // public componentDidMount = () => {
  //   const obj = JSON.parse(this.storeLocal.getFromLocalStorage(`${this.props.componentId}`));
  //   /** If component ID is  */
  //   if(Object.keys(obj).length > 0) {
  //     this.setState(obj);
  //   }
  // }

  public onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    await this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    } as { [K in keyof AppState]: AppState[K] });
    await this.props.updateData(this.props.officeId, this.props.prototypeId, this.props.componentId, this.state);

    /** Store component to the cache, according to component ID */
    await this.storeLocal.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  public render() {
    // Clear the storage after form deployed

    return (
      <div>
        <div>Držitel:</div>
        <div>
          <span>Jméno a příjmení držitele psa</span>
          <span>
            <input
              name={"psi_drzitel_name"}
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.psi_drzitel_name}
            />
          </span>
        </div>

        <div>
          <span>Rodné číslo držitele</span>
          <span>
            <input
              name={"psi_drzitel_rc"}
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.psi_drzitel_rc}
            />
          </span>
        </div>

        <div>
          <span>Adresa místa trvalého pobytu držitele</span>
          <span>
            <input
              name={"psi_drzitel_adresa"}
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.psi_drzitel_adresa}
            />
          </span>
        </div>

        <div>
          <span>Doručovací adresa držitele (pokud se liší od adresy místa trvalého pobytu)</span>
          <span>
            <input
              name={"psi_drzitel_dorucovaci_adresa"}
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.psi_drzitel_dorucovaci_adresa}
            />
          </span>
        </div>

        <div>
          <span>Počet bytů v budově, ve které je držitel psa hlášen k trvalému pobytu</span>
          <span>
            <input
              name={"psi_drzitel_pocet_bytu"}
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.psi_drzitel_pocet_bytu}
            />
          </span>
        </div>

        <div>
          <span>
            Jsem – nejsem poživatelem starobního, invalidního, vdovského, vdoveckého nebo sirotčího důchodu. Tento
            důchod je – není jediným zdrojem mého příjmu.
          </span>
          <span>
            <input
              name={"psi_drzitel_duchodce"}
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.psi_drzitel_duchodce}
            />
          </span>
        </div>

        <div>
          <span>Stáří psa (v měsících nebo létech)</span>
          <span>
            <input
              name={"psi_drzitel_stari_psa"}
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.psi_drzitel_stari_psa}
            />
          </span>
        </div>

        <div>
          <span>Datum, od kterého je pes držen</span>
          <span>
            <input
              name={"psi_drzitel_datum_drzeni"}
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.psi_drzitel_datum_drzeni}
            />
          </span>
        </div>
      </div>
    );
  }
}

export default FormPsiDrzitel;
