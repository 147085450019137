import React from "react";
import moment from "moment";

const Pdf = ({ data: { blizsi_popis, nazev, pocet_ucastniku, termin, zpusob_zajisteni } }) => (
  <React.Fragment>
    <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
      Popis akce
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Název akce:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{nazev}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Termín konání akce:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{termin && moment(termin).format("D. M. YYYY")}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Předpokládaný počet účastníků:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{pocet_ucastniku}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Bližší popis akce:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{blizsi_popis}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Způsob zajištění akce:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{zpusob_zajisteni}</span>
      </div>
    </div>
  </React.Fragment>
);
export default Pdf;
