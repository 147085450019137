import * as React from "react";
import { ZadatelProps } from "./ZadatelProps";

const SubjektPdf = (props: ZadatelProps) => {
  if (props.data.fyzicka_osoba) {
    return (
      <React.Fragment>
        <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
          {props.name}
        </div>
        <div className={"row"}>
          <div className={"label-title col-3"}>Jméno:</div>
          <div className={"col-9"}>
            <span className={"underline-dotted"}>{props.data.zadatel_name}</span>
          </div>
        </div>
        <div className={"row"}>
          <div className={"label-title col-3"}>Příjmení:</div>
          <div className={"col-9"}>
            <span className={"underline-dotted"}>{props.data.zadatel_surname}</span>
          </div>
        </div>
        <div className={"row"}>
          <div className={"label-title col-3"}>Rodné číslo / Datum narození:</div>
          <div className={"col-9"}>
            <span className={"underline-dotted"}>{props.data.zadatel_rc}</span>
          </div>
        </div>
        <div className={"row"}>
          <div className={"label-title col-3"}>Telefon:</div>
          <div className={"col-9"}>
            <span className={"underline-dotted"}>{props.data.telefon}</span>
          </div>
        </div>
        <div className={"row"}>
          <div className={"label-title col-3"}>Email:</div>
          <div className={"col-9"}>
            <span className={"underline-dotted"}>{props.data.email}</span>
          </div>
        </div>
        <div className={"row"}>
          <div className={"label-title col-3"}>Adresa místa trvalého pobytu:</div>
          <div className={"col-9"}>
            <span className={"underline-dotted"}>{props.data.zadatel_adresa}</span>
          </div>
        </div>
        {props.data.zadatel_dorucovaci_adresa && (
          <div className={"row"}>
            <div className={"label-title col-3"}>Doručovací adresa poplatníka:</div>
            <div className={"col-9"}>
              <span className={"underline-dotted"}>{props.data.zadatel_dorucovaci_adresa}</span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
        {props.name}
      </div>
      <div className={"row"}>
        <div className={"label-title col-3"}>Název:</div>
        <div className={"col-9"}>
          <span className={"underline-dotted"}>{props.data.zadatel_name}</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"label-title col-3"}>IČO:</div>
        <div className={"col-9"}>
          <span className={"underline-dotted"}>{props.data.zadatel_ico}</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"label-title col-3"}>Telefon:</div>
        <div className={"col-9"}>
          <span className={"underline-dotted"}>{props.data.telefon}</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"label-title col-3"}>Email:</div>
        <div className={"col-9"}>
          <span className={"underline-dotted"}>{props.data.email}</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"label-title col-3"}>Adresa sídla:</div>
        <div className={"col-9"}>
          <span className={"underline-dotted"}>{props.data.zadatel_adresa}</span>
        </div>
      </div>
      {props.data.zadatel_dorucovaci_adresa && (
        <div className={"row"}>
          <div className={"label-title col-3"}>Doručovací adresa:</div>
          <div className={"col-9"}>
            <span className={"underline-dotted"}>{props.data.zadatel_dorucovaci_adresa}</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SubjektPdf;
