import * as React from "react";
import ProvozovnaProps from "./ProvozovnaProps";

const KapacitaProvozovnaPdf = (props: ProvozovnaProps) => (
  <React.Fragment>
    <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
      {props.name}
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Adresa provozovny :</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{props.data.kapacita_provozovna_adresa}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Kapacita - počet lůžek:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{props.data.kapacita_provozovna_kapacita}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Počet využitých lůžek za příslušné čtvrtletí:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{props.data.kapacita_provozovna_luzka}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Celková výše poplatku:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{props.data.kapacita_provozovna_poplatek} ,-</span>
      </div>
    </div>
  </React.Fragment>
);

export default KapacitaProvozovnaPdf;
