import * as React from "react";

const KapacitaHeaderPdf = () => (
  <React.Fragment>
    <div style={{ textAlign: "left", fontWeight: 800 }}>
      <h2>Přiznání místního poplatku z ubytovací kapacity</h2>
    </div>
    <div style={{ textAlign: "left" }}>
      <small style={{ fontWeight: 800 }}>
        Dle ustanovení § 14a zákona č. 595/1990 Sb., o místních poplatcích, ve znění pozdějších předpisů, jste povinen
        ohlásit tyto údaje
      </small>
    </div>
  </React.Fragment>
);

export default KapacitaHeaderPdf;
