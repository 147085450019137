import gql from "graphql-tag";

const ORGANISATION_QUERY = gql`
  query OrganisationQuery($id_organisations: String!) {
    organisation(id_organisations: $id_organisations) {
      id_organisations
      name
      slug
      street_name
      house_number
      city
      www
      zip_code
      phone_number
      mobile_number
      contact_email
      registry_email
      registry_verified
    }
  }
`;

export default ORGANISATION_QUERY;
