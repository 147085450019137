exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3PpkADT9xbmVeMY2tAjWVk{padding:2% 4% 2% 4%}._1a_BP8X3hiBRLggYxdO4Cn{margin:0 auto;border:1px dashed #000;width:50%;height:100px;text-align:center}._1a_BP8X3hiBRLggYxdO4Cn p{margin-top:6%}._1g219hWqYXWxu7l6B9_RLm{height:50px}._1VXfU8YmL7KaWPwF52izm-{margin:0 auto;width:54%;border:1px dashed rgba(0,0,0,0.1);font-weight:bold;padding:20px}._2B22WZOg6jm6VR6KrGPUh{float:right;font-weight:600;color:#9e9e9e;text-align:center;vertical-align:middle;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-color:#dbdbdb;border:1px solid #c2c2c2;padding:.235rem .55rem;font-size:1rem;line-height:1.0;border-radius:.25rem;transition:color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;cursor:pointer}._2B22WZOg6jm6VR6KrGPUh:hover{background-color:#d4d4d4;color:#696969}\n", ""]);

// Exports
exports.locals = {
	"dropzoneAside": "_3PpkADT9xbmVeMY2tAjWVk",
	"dropzoneArea": "_1a_BP8X3hiBRLggYxdO4Cn",
	"hole": "_1g219hWqYXWxu7l6B9_RLm",
	"dropzoneElements": "_1VXfU8YmL7KaWPwF52izm-",
	"dropzoneRemove": "_2B22WZOg6jm6VR6KrGPUh"
};