import React from "react";

// Import regular stylesheet

// Import Local storage
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// Interfaces
export interface AppProps {
  data: any;
  name?: string;
}

class FormPsiDrzitel extends React.Component<AppProps> {
  /** ID for local storage */
  private componentName = "form_kapacita_poplatnik";

  constructor(props: AppProps) {
    super(props);
  }

  public render() {
    return (
      <fieldset>
        <legend>
          <FontAwesomeIcon icon={faUser} className={"mr-2"} /> Poplatník
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="jmeno" className="col-lg-3 col-form-label text-lg-right">
            Jméno
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                value={this.props.data.kapacita_poplatnik_name}
                id="jmeno"
                name={"kapacita_poplatnik_name"}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prijmeni" className="col-lg-3 col-form-label text-lg-right">
            Příjmení
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="prijmeni"
                name={"kapacita_poplatnik_surname"}
                value={this.props.data.kapacita_poplatnik_surname}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="rc" className="col-lg-3 col-form-label text-lg-right">
            Rodné číslo / Datum narození
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <input
                type="text"
                className="form-control"
                id="rc"
                pattern={"[d]{5}"}
                name={"kapacita_poplatnik_rc"}
                value={this.props.data.kapacita_poplatnik_rc}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="adresa" className="col-lg-3 col-form-label text-lg-right">
            Adresa místa trvalého pobytu poplatníka
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="adresa"
                name={"kapacita_poplatnik_adresa"}
                value={this.props.data.kapacita_poplatnik_adresa}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="doruc_adresa" className="col-lg-3 col-form-label text-lg-right">
            Doručovací adresa poplatníka
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                aria-describedby="doruc_adresa"
                id="doruc_adresa"
                name={"kapacita_poplatnik_dorucovaci_adresa"}
                value={this.props.data.kapacita_poplatnik_dorucovaci_adresa}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default FormPsiDrzitel;
