import gql from "graphql-tag";

const ORGANISATIONS_QUERY = gql`
  {
    organisations {
      id_organisations
      name
      slug
    }
  }
`;

export default ORGANISATIONS_QUERY;
