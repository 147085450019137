import React from "react";

// Interfaces
export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
}
export interface AppProps {
  urad: any;
  form: any;
}
export interface AppState {
  state: any;
}

class Test extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      state: "state"
    };
  }

  public render() {
    return <div>Test</div>;
  }
}

export default Test;
