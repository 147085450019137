import React, { Component } from "react";
import { PopisState } from "./PopisState";
import PopisProps from "./PopisProps";
import StoreLocal from "../../../libs/LocalStorage";
import { LocalStorage } from "../../../interfaces/LocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import InputTextarea from "../../InputTextarea";

export default class DrevinyPopis extends Component<PopisProps, PopisState> {
  private storeLocal: LocalStorage = new StoreLocal();

  private componentName = "form_dreviny_popis";

  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);

  constructor(props: PopisProps) {
    super(props);

    this.state = {
      druh_dreviny: "",
      katastralni_uzemi: "",
      parcela: "",
      strucny_popis: "",
      odvod_kmene: 0,
      pocet_kacenych_drevin: 0,
      vymera_kacene_plochy: 0
    };
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.storeLocal.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  private onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    await this.setState(({
      [e.currentTarget.name]: e.currentTarget.value
    } as unknown) as { [K in keyof PopisState]: PopisState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.storeLocal.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faClipboard} className={"mr-2"} /> Popis kácení
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="katastralni_uzemi" className="col-lg-3 col-form-label text-lg-right">
            Katastrální území
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="katastralni_uzemi"
                name={"katastralni_uzemi"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.katastralni_uzemi}
                placeholder={"Katastrální území"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="parcela" className="col-lg-3 col-form-label text-lg-right">
            Parcela
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="parcela"
                name={"parcela"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.parcela}
                placeholder={"Parcela"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="strucny_popis" className="col-lg-3 col-form-label text-lg-right">
            Stručný popis umístění
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <InputTextarea
                className="form-control"
                id="strucny_popis"
                name={"strucny_popis"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.strucny_popis}
                placeholder={"Stručný popis umístění"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="druh_dreviny" className="col-lg-3 col-form-label text-lg-right">
            Druh dřeviny
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type={"text"}
                className="form-control"
                id="druh_dreviny"
                name={"druh_dreviny"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.druh_dreviny}
                placeholder={"Druh dřeviny"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="pocet_kacenych_drevin" className="col-lg-3 col-form-label text-lg-right">
            Počet kácených dřevin
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type={"number"}
                className="form-control"
                id="pocet_kacenych_drevin"
                name={"pocet_kacenych_drevin"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.pocet_kacenych_drevin}
                placeholder={"Počet kácených dřevin"}
                min={0}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="vymera_kacene_plochy" className="col-lg-3 col-form-label text-lg-right">
            Výměra kácené plochy
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type={"number"}
                className="form-control"
                id="vymera_kacene_plochy"
                name={"vymera_kacene_plochy"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.vymera_kacene_plochy}
                placeholder={"Výměra kácené plochy"}
                min={0}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="odvod_kmene" className="col-lg-3 col-form-label text-lg-right">
            Obvod kmene ve výšce 130cm nad zemí
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type={"number"}
                className="form-control"
                id="odvod_kmene"
                name={"odvod_kmene"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.odvod_kmene}
                placeholder={"Obvod kmene ve výšce 130cm nad zemí"}
                min={0}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
