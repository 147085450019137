import React from "react";

const InformaceSpecifikace = ({ data }) => (
  <React.Fragment>
    <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
      Specifikace žádosti
    </div>
    <div className={"row no-gutters"}>
      <p>{data.informace_specifikace}</p>
    </div>
  </React.Fragment>
);

export default InformaceSpecifikace;
