/**
 * P12 Certificate functions
 */
import forge from "node-forge";

class GetCertificate {
  checkFileSuffix = (file: any) => {
    let fileExt = file.name.split(".").pop();
    switch (fileExt) {
      case "p12":
        return true;
      case "pfx":
        return true;
      default:
        return false;
    }
  };

  convertArrayBufferToString(buffer: Buffer) {
    const bufView = new Uint8Array(buffer);
    let length = bufView.length;
    let result = "";
    for (let i = 0; i < length; i += 65535) {
      let addition = 65535;
      if (i + 65535 > length) {
        addition = length - i;
      }
      result += String.fromCharCode.apply(null, bufView.subarray(i, i + addition));
    }
    return result;
  }

  getPrivatePEM = async (p12DER: any, passwd: string) => {
    // load the P12 Object
    const p12 = await this.loadP12(p12DER, passwd);
    const privateKeyPEM = await this.parseP12(p12);
    return privateKeyPEM;
  };

  getCertIssuer = (cert: any) => {
    let result: any = {
      state: {
        value: ""
      },
      country: {
        value: ""
      },
      organization: {
        value: ""
      },
      organizationalUnit: {
        value: ""
      },
      locality: {
        value: ""
      },
      commonName: {
        value: ""
      },
      serialNumber: {
        value: ""
      }
    };
    if (cert.issuer.getField("S") !== null) {
      result.state.value = forge.util.decodeUtf8(cert.issuer.getField("S").value);
    } else {
      result.state = null;
    }
    if (cert.issuer.getField("C") !== null) {
      result.country.value = forge.util.decodeUtf8(cert.issuer.getField("C").value);
    } else {
      result.country = null;
    }
    if (cert.issuer.getField("O") !== null) {
      result.organization.value = forge.util.decodeUtf8(cert.issuer.getField("O").value);
    } else {
      result.organization = null;
    }
    if (cert.issuer.getField("OU") !== null) {
      result.organizationalUnit.value = forge.util.decodeUtf8(cert.issuer.getField("OU").value);
    } else {
      result.organizationalUnit = null;
    }
    if (cert.issuer.getField("L") !== null) {
      result.locality.value = forge.util.decodeUtf8(cert.issuer.getField("L").value);
    } else {
      result.locality = null;
    }
    if (cert.issuer.getField("CN") !== null) {
      result.commonName.value = forge.util.decodeUtf8(cert.issuer.getField("CN").value);
    } else {
      result.commonName = null;
    }
    return result;
  };

  /**
   * Načte certifikační řetězec z P12
   */
  loadCertificates = async (p12: any) => {
    let certBags = p12.getBags({ bagType: forge.pki.oids.certBag });
    let certs: any = certBags[forge.pki.oids.certBag];

    let pkeyBags = p12.getBags({ bagType: forge.pki.oids.pkcs8ShroudedKeyBag });
    // fetching certBag
    let certBag = certBags[forge.pki.oids.certBag][0];
    // fetching keyBag
    let keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];
    // generate pem from cert
    let certificate = certBag.cert;

    let issuer = this.getCertIssuer(certificate);

    issuer["serialNumber"].value = certificate.serialNumber;

    let certObjects: any = [];
    for (let i = 0; i < certs.length; i++) {
      certObjects.push(certs[i].cert);
    }
    console.log(certObjects);
    if (this.setCertChain(certObjects)) {
      console.log("SUCC_CERT_LOADED" + " [" + this.getStamp() + "]");
    }

    return issuer;
  };

  /**
   * Načte Private Key
   */
  loadPrivateKey = (p12: any) => {
    let key = null;
    for (let i = 0; i < p12.safeContents.length; i++) {
      let safeBags = p12.safeContents[i].safeBags;
      for (let j = 0; j < safeBags.length; j++) {
        if (safeBags[j].key !== undefined) {
          key = safeBags[j].key;
          break;
        }
      }
    }
    if (key === undefined || key === null) {
      return "ERROR";
    }
    const privateKeyPEM = forge.pki.privateKeyToPem(key);
    // const privateKeyDER = this.convertPrivateKeyToDER(key);s
    return privateKeyPEM;
  };

  convertPrivateKeyToDER = (key: any) => {
    const rsaPrivateKey: any = forge.pki.privateKeyToPem(key);
    const privateKeyInfo: any = forge.pki.privateKeyInfoToPem(rsaPrivateKey);
    return forge.asn1.toDer(privateKeyInfo).getBytes();
  };

  isUserCert = async (cert: any) => {
    return cert.getExtension("basicConstraints") === null || cert.getExtension("basicConstraints").cA === false;
  };

  getStamp = () => {
    let date = new Date();
    return date.toLocaleString();
  };

  setCertChain = async (certs: any) => {
    let certChain: any = [];
    for (let i = 0; i < certs.length; i++) {
      let cert = certs[i];
      if (this.isUserCert(cert)) {
        certChain.push(cert);
        certs.splice(i, 1);
        break;
      }
    }
    if (certChain.length === 0) {
      console.log("ERR_USER_CERT_NOT_FOUND" + " [" + this.getStamp() + "]");
      return false;
    }

    let foundCerts = 1;
    while (certs.length !== 0) {
      for (let i = 0; i < certs.length; i++) {
        let cert = certs[i];
        if (certChain.length === 1) {
          // mame zatim pouze uzivatelsky
          if (certChain[0].isIssuer(cert)) {
            // cert je issuer uzivatelskeho
            certChain.push(cert);
            certs.splice(i, 1);
            foundCerts++;
            break;
          }
        } else if (certChain[foundCerts - 1].isIssuer(cert)) {
          // cert je issuer posledniho v sestavenem retezci
          certChain.push(cert);
          certs.splice(i, 1);
          foundCerts++;
          break;
        }
      }
    }
    return true;
  };

  parseP12 = async (p12: any) => {
    await this.loadCertificates(p12);
    const privateKeyPEM = await this.loadPrivateKey(p12);
    return privateKeyPEM;
  };

  loadP12 = function(p12DER: any, passwd: string) {
    let p12Der = p12DER;
    let p12Asn1: any;
    try {
      p12Asn1 = forge.asn1.fromDer(p12DER);
    } catch (error) {
      console.log(error.message);
    }
    try {
      return forge.pkcs12.pkcs12FromAsn1(p12Asn1, passwd);
    } catch (error) {
      console.log(error.message);
    }
  };

  getPublicKey = (includeStartEndMarks: boolean, privateKeyPEM: any) => {
    if (includeStartEndMarks === undefined) {
      includeStartEndMarks = true;
    }
    let privateKey: any = forge.pki.privateKeyFromPem(privateKeyPEM);
    let publicKey: any = forge.pki.rsa.setPublicKey(privateKey.n, privateKey.e);
    if (includeStartEndMarks) {
      return forge.pki.publicKeyToPem(publicKey);
    }
    return this.stripPEMPublicKeyBeginEnd(forge.pki.publicKeyToPem(publicKey));
  };

  stripPEMPublicKeyBeginEnd = (publicKey: any) => {
    return publicKey
      .replace(/-----BEGIN PUBLIC KEY-----/, "")
      .replace(/-----END PUBLIC KEY-----/, "")
      .trim();
  };
}

export default GetCertificate;
