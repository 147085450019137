import React, { SyntheticEvent } from "react";
import ZadostProps from "./ZadostProps";
import { ZadostState } from "./ZadostState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons/faClipboardCheck";
import { LocalStorage } from "../../../interfaces/LocalStorage";
import StoreLocal from "../../../libs/LocalStorage";

export default class FormProstrediZadost extends React.Component<ZadostProps, ZadostState> {
  private componentName = "form_prostredi_zadost";
  private localStorage: LocalStorage = new StoreLocal();
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);

  constructor(props: ZadostProps) {
    super(props);

    this.state = {
      ucel: 0
    };

    this.handleChange = this.handleChange.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.localStorage.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  async handleChange(e: any) {
    this.setState({
      ucel: +e.target.value
    });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  }

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faClipboardCheck} className={"mr-2"} /> Typ žádosti
        </legend>

        <div className="form-group row justify-content-md-center">
          <div className="row col-lg-6">
            <div className="col-12 custom-control custom-radio">
              <input
                type="radio"
                id="ucel1"
                name="ucel"
                className="custom-control-input"
                value={1}
                onChange={this.handleChange}
                checked={this.state.ucel === 1}
              />
              <label className="custom-control-label" htmlFor="ucel1">
                k územnímu řízení
              </label>
            </div>

            <div className="col-12 custom-control custom-radio">
              <input
                type="radio"
                id="ucel2"
                name="ucel"
                value={2}
                onChange={this.handleChange}
                checked={this.state.ucel === 2}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="ucel2">
                k stavebnímu řízení
              </label>
            </div>

            <div className="col-12 custom-control custom-radio">
              <input
                type="radio"
                id="ucel3"
                name="ucel"
                value={3}
                onChange={this.handleChange}
                checked={this.state.ucel === 3}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="ucel3">
                ke změně stavby před jejím dokončením
              </label>
            </div>

            <div className="col-12 custom-control custom-radio">
              <input
                type="radio"
                id="ucel4"
                name="ucel"
                value={4}
                onChange={this.handleChange}
                checked={this.state.ucel === 4}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="ucel4">
                ke kolaudaci
              </label>
            </div>

            <div className="col-12 custom-control custom-radio">
              <input
                type="radio"
                id="ucel5"
                name="ucel"
                value={5}
                onChange={this.handleChange}
                checked={this.state.ucel === 5}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="ucel5">
                k odstranění stavby
              </label>
            </div>

            <div className="col-12 custom-control custom-radio">
              <input
                type="radio"
                id="ucel6"
                name="ucel"
                value={6}
                onChange={this.handleChange}
                checked={this.state.ucel === 6}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="ucel6">
                ke změně v využívání stavby
              </label>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
