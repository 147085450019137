import React from "react";

const Pdf = () => (
  <div style={{ textAlign: "left", fontWeight: 800 }}>
    <h2>
      Přiznání k místnímu poplatku za provoz systému shromažďování, sběru, přepravy, třídění, využívání a odstraňování
      komunálních odpadů
    </h2>
  </div>
);

export default Pdf;
