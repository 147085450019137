import gql from "graphql-tag";

const COMPONENT_QUERY = gql`
  query ComponentQuery($id: String!) {
    component(id: $id) {
      name
      slug
      elements
    }
  }
`;

export default COMPONENT_QUERY;
