import React, { Component } from "react";
import { ZadatelProps } from "./ZadatelProps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";

export default class Subjekt extends Component<ZadatelProps> {
  constructor(props: ZadatelProps) {
    super(props);
  }

  public render() {
    if (this.props.data.fyzicka_osoba || this.props.data.fyzicka_osoba === "") {
      return (
        <fieldset className={"mt-5"}>
          <legend>
            <FontAwesomeIcon icon={faUserTie} className={"mr-2"} /> {this.props.name}
          </legend>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="zadatel_name" className="col-lg-3 col-form-label text-lg-right">
              Jméno
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input type="text" className="form-control" value={this.props.data.zadatel_name} readOnly={true} />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="zadatel_surname" className="col-lg-3 col-form-label text-lg-right">
              Příjmení
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="zadatel_surname"
                  name={"zadatel_surname"}
                  value={this.props.data.zadatel_surname}
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="zadatel_rc" className="col-lg-3 col-form-label text-lg-right">
              Rodné číslo / Datum narození
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="zadatel_rc"
                  name={"zadatel_rc"}
                  value={this.props.data.zadatel_rc}
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="telefon" className="col-lg-3 col-form-label text-lg-right">
              Telefon
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="telefon"
                  name={"telefon"}
                  value={this.props.data.telefon}
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="email" className="col-lg-3 col-form-label text-lg-right">
              Email
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="email"
                  className="form-control"
                  id="email"
                  name={"email"}
                  value={this.props.data.email}
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="zadatel_adresa" className="col-lg-3 col-form-label text-lg-right">
              Adresa místa trvalého pobytu
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="zadatel_adresa"
                  name={"zadatel_adresa"}
                  value={this.props.data.zadatel_adresa}
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="zadatel_dorucovaci_adresa" className="col-lg-3 col-form-label text-lg-right">
              Doručovací adresa
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="zadatel_dorucovaci_adresa"
                  name={"zadatel_dorucovaci_adresa"}
                  value={this.props.data.zadatel_dorucovaci_adresa}
                  readOnly={true}
                />
                <small>pokud se liší od adresy místa trvalého pobytu</small>
              </div>
            </div>
          </div>
        </fieldset>
      );
    }

    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faUserTie} className={"mr-2"} /> {this.props.name}
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="zadatel_name" className="col-lg-3 col-form-label text-lg-right">
            Název
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="zadatel_name"
                name={"zadatel_name"}
                value={this.props.data.zadatel_name}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="zadatel_ico" className="col-lg-3 col-form-label text-lg-right">
            IČO
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="zadatel_ico"
                name={"zadatel_ico"}
                value={this.props.data.zadatel_ico}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="telefon" className="col-lg-3 col-form-label text-lg-right">
            Telefon
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="telefon"
                name={"telefon"}
                value={this.props.data.telefon}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="email" className="col-lg-3 col-form-label text-lg-right">
            Email
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="email"
                className="form-control"
                id="email"
                name={"email"}
                value={this.props.data.email}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="zadatel_adresa" className="col-lg-3 col-form-label text-lg-right">
            Adresa sídla
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="zadatel_adresa"
                name={"zadatel_adresa"}
                value={this.props.data.zadatel_adresa}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="zadatel_dorucovaci_adresa" className="col-lg-3 col-form-label text-lg-right">
            Doručovací adresa
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="zadatel_dorucovaci_adresa"
                name={"zadatel_dorucovaci_adresa"}
                value={this.props.data.zadatel_dorucovaci_adresa}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
