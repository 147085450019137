import React, { Suspense } from "react";

// GraphQL query
import { Query } from "react-apollo";
import COMPONENT_QUERY from "../../queries/component";

// Interfaces
export interface AppProps {
  officeId: string;
  prototypeId: string;
  componentId: string;
  componentName?: string;
  data: Object;
  updateData(officeId: string, prototypeId: string, componentId: string, componentName: string, data: Object): void;
}
interface Data {
  component: {
    id: string;
    name: string;
    slug: string;
    elements: Array<{ name: string }>;
  };
}
interface Variables {
  id: string;
}

class RenderComponent extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
  }

  public render() {
    const id: any = this.props.componentId;
    return (
      <Query<Data, Variables> query={COMPONENT_QUERY} variables={{ id }}>
        {({ loading, error, data }) => {
          if (loading) return <div>Nahrávání dat</div>; // TODO loader
          if (error) return <div>Chyba při nahrávání dat: {error}</div>;
          const _data: any = data;
          const slug: string = _data.component.slug;

          /** Lazy load the component from the Forms folder */
          const Component: any = React.lazy(() => import(`../Forms/${slug}`));
          return (
            <Suspense fallback={<div>Loading...</div>}>
              <Component
                updateData={this.props.updateData}
                data={this.props.data}
                componentName={this.props.componentName}
                officeId={this.props.officeId}
                prototypeId={this.props.prototypeId}
                componentId={id}
              />
            </Suspense>
          );
        }}
      </Query>
    );
  }
}

export default RenderComponent;
