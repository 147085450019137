import React from "react";
import { LocalStorage } from "../../../interfaces/LocalStorage";
import StoreLocal from "../../../libs/LocalStorage";
import { OsobaProps } from "./OsobaProps";
import { OsobaState } from "./OsobaState";
import { SubjektState } from "../form_subjekt/SubjektState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import { VyjadreniState } from "../form_prostredi_vyjadreni/VyjadreniState";

export default class FormKomunikaceOsoba extends React.Component<OsobaProps, OsobaState> {
  private componentName = "form_komunikace_zodpovedna_osoba";
  private localStorage: LocalStorage = new StoreLocal();
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);

  constructor(props: OsobaProps) {
    super(props);

    this.state = {
      jina_osoba: false,
      jmeno: "",
      prijmeni: "",
      adresa: "",
      telefon: ""
    };

    this.handleChange = this.handleChange.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.localStorage.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  async handleChange(e: any) {
    this.setState(({
      [e.currentTarget.name]: e.currentTarget.value
    } as unknown) as { [K in keyof OsobaState]: OsobaState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  }

  private async changeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    await this.setState(({
      [e.currentTarget.name]: e.target.checked
    } as unknown) as { [K in keyof OsobaState]: OsobaState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  }

  render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faUserTie} className={"mr-2"} /> Osoba zodpovědná za zvláštní užívání
        </legend>
        <div className="form-group row justify-content-md-center pt-3">
          <div className="row col-lg-6">
            <div className="col-12 custom-control custom-checkbox">
              <input
                type="checkbox"
                id="jina_osoba"
                name="jina_osoba"
                className="custom-control-input"
                value={+this.state.jina_osoba}
                checked={this.state.jina_osoba}
                onChange={e => this.changeCheckbox(e)}
              />
              <label className="custom-control-label" htmlFor="jina_osoba">
                Osoba zodpovědná je jiná než pořadatel
              </label>
            </div>
          </div>
        </div>
        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="jmeno" className="col-lg-3 col-form-label text-lg-right">
            Jméno
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="jmeno"
                name={"jmeno"}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={this.state.jmeno}
                placeholder={"Jméno"}
                disabled={!this.state.jina_osoba}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prijmeni" className="col-lg-3 col-form-label text-lg-right">
            Příjmení
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="prijmeni"
                name={"prijmeni"}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={this.state.prijmeni}
                placeholder={"Příjmení"}
                disabled={!this.state.jina_osoba}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="adresa" className="col-lg-3 col-form-label text-lg-right">
            Adresa
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="adresa"
                name={"adresa"}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={this.state.adresa}
                placeholder={"Adresa"}
                disabled={!this.state.jina_osoba}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="telefon" className="col-lg-3 col-form-label text-lg-right">
            Telefon
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="telefon"
                name={"telefon"}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={this.state.telefon}
                placeholder={"Telefon"}
                disabled={!this.state.jina_osoba}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
