import React from "react";

const Pdf = ({ data: { misto, nazev, trvani_zuk, ulice } }) => (
  <React.Fragment>
    <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
      Popis akce
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Název akce:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{nazev}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Místo akce:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{misto}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Ulice:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{ulice}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Délka trvání ZUK:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{trvani_zuk}</span>
      </div>
    </div>
  </React.Fragment>
);

export default Pdf;
