import React from "react";

// Semantic UI
import { Button } from "semantic-ui-react";
import GetCertificate from "../../libs/GetCertificate";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheck,
  faClipboardCheck,
  faFileExport,
  faExclamation,
  faMoon,
  faKey
} from "@fortawesome/free-solid-svg-icons";
import forge from "node-forge";

import { Alert, Input, Spinner } from "reactstrap";

library.add(faSpinner, faCheck, faExclamation);

// External functions
const getCertificate: any = new GetCertificate();

// Interfaces
export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
}

export interface AppProps {
  submittingSignedForm: any;
  handleReload: any;
  sendKeysToSubmit: any;
  passwordVerified: any;
  certIssuer: any;
}

export interface AppState {
  file: any;
  password: any;
  values: any;
  data: any;
  name?: string;
  passwordVerified: boolean;
  privateKey: any;
  publicKey: any;
  submittingSignedForm: boolean;
  passwordError: boolean;
  fileError: boolean;
  certIssuer: any;
}

class Signed extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      file: {},
      password: "",
      values: {},
      data: {},
      passwordVerified: false,
      privateKey: "",
      publicKey: "",
      submittingSignedForm: false,
      passwordError: false,
      fileError: false,
      certIssuer: {}
    };
    this.handleFileInput = this.handleFileInput.bind(this);
    this.handleVerifyPassword = this.handleVerifyPassword.bind(this);
  }

  handleFileInput = (e: any) => {
    this.setState({ fileError: false });
    const inputFile = e.target.files;
    const file = inputFile[0];
    if (file) {
      const sfx: any = /[.]/.exec(file.name) ? /[^.]+$/.exec(file.name) : undefined;
      const suffix = sfx[0].toLowerCase();
      if (suffix === "pfx" || suffix === "p12") {
        this.setState({ file: file });
      } else {
        this.setState({ file: {} });
        this.setState({ fileError: true });
      }
    }
  };

  private handlePasswordInput = (event: any) => {
    event.preventDefault();
    this.setState({ passwordError: false });
    const password: any = event.target.value;
    if (password) {
      this.setState({ password: password });
    }
    return null;
  };

  private handleVerifyPassword = (event: any) => {
    event.preventDefault();
    if (this.state.file.name && this.state.password) {
      const suffixOk: boolean = getCertificate.checkFileSuffix(this.state.file);
      this.fileRead(suffixOk, this.state.file, this.state.password);
    }
  };

  private fileRead = async (isOk: boolean, file: File, password: string) => {
    if (isOk) {
      let reader = new FileReader();
      let self = this;

      reader.onload = await function(event: any) {
        try {
          self.runDataFlow(event.target.result, password);
        } catch (err) {
          console.log(err);
        }
      };
      await reader.readAsArrayBuffer(file);
    } else {
      throw new Error("FILE HAS WRONG SUFFIX");
    }
  };

  private runDataFlow = async (buffer: Buffer, password: string) => {
    const stringedBuffer: string = await getCertificate.convertArrayBufferToString(buffer);

    try {
      const privateKeyPEM = await getCertificate.getPrivatePEM(stringedBuffer, password);

      const p12 = getCertificate.loadP12(stringedBuffer, password);
      const issuer = await getCertificate.loadCertificates(p12);

      await this.setState({ certIssuer: issuer });
      await this.props.certIssuer(issuer);

      if (privateKeyPEM) {
        await this.setState({ privateKey: privateKeyPEM });
        const publicKeyPEM = await getCertificate.getPublicKey(true, privateKeyPEM);
        await this.setState({ publicKey: publicKeyPEM });
        await this.props.sendKeysToSubmit(privateKeyPEM, publicKeyPEM);
        if (publicKeyPEM) {
          await this.setState({ passwordVerified: true });
          await this.props.passwordVerified(true);
        } else {
          await this.setState({ passwordError: true });
        }
      } else {
        this.setState({ passwordError: true });
      }
    } catch (error) {
      console.log(error);
      this.setState({ passwordError: true });
    }
  };

  render() {
    let file = this.state.file.name || "";
    return (
      <div className={"row justify-content-center align-self-center"}>
        <div className={!this.state.passwordVerified ? "col-12" : "d-none"}>
          <Alert color={"danger"} className={this.state.passwordError ? "" : "d-none"}>
            Vložené heslo je nesprávné
          </Alert>
        </div>
        <div className={"col-12 text-center"}>
          <label htmlFor={"file"} className={"dropzone__label btn btn-primary"}>
            Vybrat klíč k podepsání dokumentu
          </label>
          <input
            type="file"
            id={"file"}
            name="input-pfx-file"
            className={"dropzone__file"}
            onChange={(e: any) => this.handleFileInput(e)}
          />
        </div>
        <div className={"text-center"}>
          {file ? <Alert color={"dark"}>{file}</Alert> : ""}
          <small className={this.state.fileError ? "text-danger col-12" : "text-success col-12"}>
            Formát PFX, nebo P12
          </small>
        </div>

        <div className="col-12">
          <div className="input-group mb-4 mt-4 justify-content-center">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faKey} />
              </div>
            </div>
            <Input
              type="password"
              name="input-pfx-password"
              className="form-control col-xs-12 col-md-6 col-xl-6"
              id="inputPfxpassword"
              placeholder="Heslo vašeho klíče"
              onChange={(e: any) => this.handlePasswordInput(e)}
              disabled={!file}
              error={this.state.passwordError}
            />
          </div>
        </div>
        <div className={this.state.passwordVerified ? "col-12" : "d-none"}>
          <Alert color={"success"} className={this.props.submittingSignedForm ? "d-none" : ""}>
            <FontAwesomeIcon icon={faCheck} /> Váš klíč byl ověřen
          </Alert>
          <div className={!this.props.submittingSignedForm ? "d-none" : ""}>Formulář se odesílá</div>
        </div>
        <Button
          type="button"
          className={!this.state.passwordVerified ? "btn btn-outline-success col-3" : "d-none"}
          onClick={(e: any) => this.handleVerifyPassword(e)}
          disabled={!file}
        >
          Ověřit heslo
        </Button>
        <div className={this.state.passwordVerified ? "col-12 text-center" : "d-none"}>okno se automaticky zavře</div>
      </div>
    );
  }
}

export default Signed;
