import React from "react";
import HeaderProps from "./HeaderProps";
import { LocalStorage } from "../../../interfaces/LocalStorage";
import StoreLocal from "../../../libs/LocalStorage";

export default class FormProstrediHeader extends React.Component<HeaderProps> {
  constructor(props: HeaderProps) {
    super(props);
  }

  public render() {
    return (
      <div className="row mb-4 mt-5">
        <div className="col">
          <div className={"text-center"}>
            <h2>
              <strong>
                Žádost o povolení zvláštního užívání pozemní komunikace pro pořádání sportovní nebo kulturně společenské
                akce
              </strong>
            </h2>
          </div>
        </div>
      </div>
    );
  }
}
