import React from 'react';

export default class FormAkceLesHeader extends React.Component {
  public render() {
    return (
      <div className='row mb-4 mt-5'>
        <div className='col'>
          <div className={'text-center'}>
            <h2>
              <strong>Oznámení o konání organizované nebo hromadné sportovní akce v lese</strong>
            </h2>
          </div>
        </div>
      </div>
    );
  }
}
