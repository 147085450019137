import React from "react";

// Interfaces
export interface AppProps {
  officeId: string;
  prototypeId: string;
  componentId: string;
  data: Object;
  updateData(offficeId: string, prototypeId: string, componentId: string, componentName: string, data: Object): void;
}

class FormPsiHeader extends React.Component<AppProps> {
  private componentName = "form_psi_header";
  constructor(props: AppProps) {
    super(props);
  }

  public componentDidMount = async () => {
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.props.data
    );
  };
  public render() {
    return (
      <div className="row mb-4">
        <div className="col">
          <div className={"text-center"}>
            <h2>
              <strong>Přiznání k místnímu poplatku za psa</strong>
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

export default FormPsiHeader;
