import * as React from "react";
import "./forms.scss";
import Button from "@material-ui/core/Button";

export interface Props {}

export interface State {
  selectedOption?: string;
  selectedOption2?: string;
  trimmedDataURL: any;
}

class Odpad extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      trimmedDataURL: null,
      selectedOption: "option1",
      selectedOption2: "option5"
    };
  }
  sigPad: any = {};

  clear = () => {
    this.sigPad.clear();
  };
  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png")
    });
  };
  render() {
    let now: any = new Date().toISOString().substring(0, 10);

    const handleOptionChange = (changeEvent: any) => {
      this.setState({
        selectedOption: changeEvent.target.value
      });
    };
    const handleOptionChange2 = (changeEvent: any) => {
      this.setState({
        selectedOption2: changeEvent.target.value
      });
    };

    return (
      <div className="the-form">
        <div className="header-b">
          PŘIZNÁNÍ K MÍSTNÍMU POPLATKU ZA PROVOZ SYSTÉMU SHROMAŽĎOVÁNÍ, SBĚRU, PŘEPRAVY, TŘÍDĚNÍ, VYUŽÍVÁNÍ A
          ODSTRAŇOVÁNÍ KOMUNÁLNÍCH ODPADŮ
        </div>
        <div className="sub-header">
          Správce místního poplatku: Městský úřad Velká Obec, Dukelské nám. 2/2, 693 17 Velká Obec
        </div>
        <div className="sub-sub-header-a">
          Dle ustanovení § 14a zákona č. 565/1990 Sb., o místních poplatcích, ve znění pozdějších předpisů, jste povinen
          ohlásit tyto údaje:
        </div>
        <div className="block-a">
          <div className="row">
            <div className="description-a">Fyzická osoba:</div>
            <form>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="option1"
                    checked={this.state.selectedOption === "option1"}
                    onChange={handleOptionChange}
                  />
                  Trvalý pobyt
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="option2"
                    checked={this.state.selectedOption === "option2"}
                    onChange={handleOptionChange}
                  />
                  Cizinec s povoleným trvalým nebo přechodným pobytem nad 90 dní
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="option3"
                    checked={this.state.selectedOption === "option3"}
                    onChange={handleOptionChange}
                  />
                  Cizinec s přechodným pobytem nad 3 měsíce
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="option4"
                    checked={this.state.selectedOption === "option4"}
                    onChange={handleOptionChange}
                  />
                  Azyl
                </label>
              </div>
            </form>
          </div>
        </div>
        <div className="block-b">
          <div className="row">
            <span className="input-label-b">Jméno, příjmení fyzické osoby-poplatníka/společného zástupce:</span>
            <span>
              <input className="input-b" />
            </span>
          </div>
          <div className="row">
            <span className="input-label-b">Rodné číslo/datum narození:</span>
            <span>
              <input className="input-b" />
            </span>
          </div>
          <div className="row">
            <span className="input-label-b">Adresa pobytu:</span>
            <span>
              <input className="input-b" />
            </span>
          </div>
          <div className="row">
            <span className="input-label-b">Poplatková povinnost vznikla od:</span>
            <span>
              <input className="input-b" />
            </span>
          </div>
        </div>

        <div className="description">
          PROHLÁŠENÍ SPOLEČNÉHO ZÁSTUPCE (poplatník vyplní jen, je-li současně společným zástupcem)
        </div>

        <div className="block-a">
          <div className="row">
            <div className="description-a"></div>
            <form>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="option5"
                    checked={this.state.selectedOption2 === "option5"}
                    onChange={handleOptionChange2}
                  />
                  společný zástupce za domácnost
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="option6"
                    checked={this.state.selectedOption2 === "option6"}
                    onChange={handleOptionChange2}
                  />
                  společný zástupce za rodinný dům
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="option7"
                    checked={this.state.selectedOption2 === "option7"}
                    onChange={handleOptionChange2}
                  />
                  společný zástupce za bytový důmm
                </label>
              </div>
            </form>
          </div>
        </div>

        <div className="description-c">
          POPLATEK JE ODVÁDĚN ZA TYTO POPLATNÍKY (uveďte jméno, příjmení, rodné číslo nebo datum narození a adresu
          trvalého nebo přechodného pobytu jednotlivých poplatníků)
        </div>

        <div className="block-b">
          <div className="row">
            <span className="input-label-e">1:</span>
            <span>
              <input className="input-d" />
            </span>
          </div>
          <div className="row">
            <span className="input-label-e">2:</span>
            <span>
              <input className="input-d" />
            </span>
          </div>
          <div className="row">
            <span className="input-label-e">3:</span>
            <span>
              <input className="input-d" />
            </span>
          </div>
        </div>

        <div className="description">Prohlašuji, že jsem veškeré údaje uvedl úplně a správně.</div>
        <div className="date">
          <span className="date-text">V</span>
          <span className="date-input">
            <input className="input-date" />
          </span>
          <span className="date-text">Dne</span>
          <span className="date-input">
            <input className="input-date" type="date" data-date="" data-date-format="DD MMMM YYYY" value={now} />
          </span>
        </div>
        <div className="signature-description">Podpis</div>
        <div className="signature-btn-container">
          <Button variant="outlined" className={"signature-btn"} onClick={this.clear}>
            Smaž podpis
          </Button>
        </div>
        <div className="odeslat">
          <Button variant="outlined" className="odeslat-btn">
            Odeslat
          </Button>
        </div>
      </div>
    );
  }
}

export default Odpad;
