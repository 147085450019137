import React from "react";

// Import regular stylesheet

// Import Local storage
import StoreLocal from "../../../libs/LocalStorage";
import { Grid, Form } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faUser } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";

// Interfaces
export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
  getFromLocalStorage: any;
}
export interface AppProps {
  officeId: string;
  prototypeId: string;
  componentId: string;
  data: Object;
  updateData(offficeId: string, prototypeId: string, componentId: string, componentName: string, data: Object): void;
}
export interface AppState {
  kapacita_provozovna_adresa: any;
  kapacita_provozovna_kapacita: any;
  kapacita_provozovna_poplatek: any;
  kapacita_provozovna_luzka: any;
}

class FormKapacitaProvozovna extends React.Component<AppProps, AppState> {
  private storeLocal: LocalStorage = new StoreLocal();
  private componentName = "form_kapacita_provozovna";
  /** ID for local storage */
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);

  constructor(props: AppProps) {
    super(props);
    this.state = {
      kapacita_provozovna_adresa: "",
      kapacita_provozovna_kapacita: "",
      kapacita_provozovna_luzka: "",
      kapacita_provozovna_poplatek: ""
    };
    this.onChange = this.onChange.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.storeLocal.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  private onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    } as { [K in keyof AppState]: AppState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.storeLocal.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faBed} className={"mr-2"} />
          Provozovna
        </legend>

        <div className={"form-group align-items-center row"}>
          <label htmlFor="kapacita_provozovna_adresa" className="col-lg-3 col-form-label text-lg-right">
            Adresa provozovny
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="kapacita_provozovna_adresa"
                name={"kapacita_provozovna_adresa"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.kapacita_provozovna_adresa}
                placeholder={"Adresa provozovny"}
              />
              <div className="invalid-feedback">Vyplňte adresu provozovny</div>
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row"}>
          <label htmlFor="kapacita_provozovna_kapacita" className="col-lg-3 col-form-label text-lg-right">
            Kapacita - počet lůžek
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="number"
                className="form-control"
                id="kapacita_provozovna_kapacita"
                name={"kapacita_provozovna_kapacita"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.kapacita_provozovna_kapacita}
                placeholder={"Kapacita - počet lůžek"}
              />
              <div className="invalid-feedback">Vyplňte kapacitu</div>
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row"}>
          <label htmlFor="kapacita_provozovna_luzka" className="col-lg-3 col-form-label text-lg-right">
            Počet využitých lůžek za příslušné čtvrtletí
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="number"
                className="form-control"
                id="kapacita_provozovna_luzka"
                name={"kapacita_provozovna_luzka"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.kapacita_provozovna_luzka}
                placeholder={"Počet využitých lůžek"}
              />
              <div className="invalid-feedback">Vyplňte počet využitých lůžek</div>
            </div>
          </div>
        </div>
        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="kapacita_provozovna_poplatek" className="col-lg-3 col-form-label text-lg-right">
            Celková výše poplatku
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="number"
                className="form-control"
                id="kapacita_provozovna_poplatek"
                name={"kapacita_provozovna_poplatek"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.kapacita_provozovna_poplatek}
                placeholder={"Celková výše poplatku"}
              />
            </div>
            <small className="form-text text-muted">
              Sazba poplatku z ubytovací kapacity činí za každé využité lůžko a den 2,- Kč.
            </small>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default FormKapacitaProvozovna;
