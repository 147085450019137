import React from "react";

// Import regular stylesheet

// Import Local storage
import StoreLocal from "../../../libs/LocalStorage";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity, faUser } from "@fortawesome/free-solid-svg-icons";

// Interfaces
export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
  getFromLocalStorage: any;
}
export interface AppProps {
  officeId: string;
  prototypeId: string;
  componentId: string;
  data: Object;
  updateData(offficeId: string, prototypeId: string, componentId: string, componentName: string, data: Object): void;
}
export interface AppState {
  informace_subjekt_name: string;
  informace_subjekt_urad: string;
  informace_subjekt_adresa: string;
  informace_subjekt_mesto: string;
}

class InformaceSubjekt extends React.Component<AppProps, AppState> {
  private storeLocal: LocalStorage = new StoreLocal();
  /** ID for local storage */
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);
  private componentName = "form_informace_subjekt";

  constructor(props: AppProps) {
    super(props);
    this.state = {
      informace_subjekt_name: "",
      informace_subjekt_urad: "",
      informace_subjekt_adresa: "",
      informace_subjekt_mesto: ""
    };
    this.onChange = this.onChange.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.storeLocal.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  private onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    await this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    } as { [K in keyof AppState]: AppState[K] });
    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.storeLocal.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faUniversity} className={"mr-2"} /> Povinný subjekt
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_subjekt_name" className="col-lg-3 col-form-label text-lg-right">
            Název
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_subjekt_name"
                name={"informace_subjekt_name"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.informace_subjekt_name}
                placeholder={"Název"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_subjekt_urad" className="col-lg-3 col-form-label text-lg-right">
            Úřad
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_subjekt_urad"
                name={"informace_subjekt_urad"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.informace_subjekt_urad}
                placeholder={"Úřad"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_subjekt_adresa" className="col-lg-3 col-form-label text-lg-right">
            Adresa
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_subjekt_adresa"
                name={"informace_subjekt_adresa"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.informace_subjekt_adresa}
                placeholder={"Adresa"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_subjekt_mesto" className="col-lg-3 col-form-label text-lg-right">
            Město
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_subjekt_mesto"
                name={"informace_subjekt_mesto"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.informace_subjekt_mesto}
                placeholder={"Město"}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default InformaceSubjekt;
